import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { LegacyButton as Button, color } from '@finabro-ui/components'

import AuthService from 'services/api/auth'
import { Wrapper, Column, Title } from 'components/Authentication/Auth'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import IconLogout from 'components/UI/SVG/Logout'

const Icon = styled(IconLogout)`
  /* color: ${color.secondary}; */

  && {
    height: 100px;
    width: 100px;
  }
`

const StyledButton = styled(Button)`
  display: block;
  margin: 2rem auto 20px;
  max-width: 330px;
  width: 100%;
`

const Link = styled.div`
  color: ${color.mediumdark};
  font-size: 0.75rem;
  text-decoration: none;

  a {
    color: ${color.mediumdark};
  }
`

const Logout = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  async function logout() {
    setIsLoading(true)

    try {
      await AuthService.logout()
      // TODO: delete refreshToken cookie
      // move accessToken to memory
      localStorage.removeItem('user')
      localStorage.removeItem('accessToken')
    } catch (error) {
      console.error(error)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    logout()
  }, [])

  return (
    <Wrapper>
      {isLoading && (
        <>
          <Title></Title>

          <div className="container">
            <LoadingSpinner text="Sie werden abgemeldet..." />
          </div>
        </>
      )}

      {!isLoading && (
        <>
          <Title>
            Sie haben sich erfolgreich abgemeldet.
            <br />
            Bis bald bei FINABRO!
          </Title>

          <Column>
            <Icon />

            <StyledButton id="button-link-login" onClick={() => history.push('/login')}>
              Zurück zum Login
            </StyledButton>

            <Link>
              <a href="https://www.finabro.at/" target="_blank">
                Hier gehts zur FINABRO Homepage
              </a>
            </Link>
          </Column>
        </>
      )}
    </Wrapper>
  )
}

export default Logout
