import classnames from 'classnames'
import { formatValue } from '@finabro-ui/components'

type Props = {
  id?: string
  className?: string
  disableFormatting?: boolean
  dynamicIncrement?: boolean
  incrementBy?: number
  forceIncrementBy?: number
  min?: number
  prefix?: string
  steps?: number
  value: number
  disableControls?: boolean
  disabled?: boolean
  onTextChange?: (value: number) => void
  onIncrement: (value: number) => void
  onDecrement: (value: number) => void
  onBlur: () => void
}

const MAX = 999999999

function validateNumber(value: string) {
  const numericValue = Number(value.replace(/\D/g, ''))
  // only allow positive values
  const currentValue = numericValue >= 0 ? numericValue : Math.abs(numericValue)
  return currentValue
}

function InputNumber({
  id,
  className,
  disableFormatting,
  dynamicIncrement,
  incrementBy,
  forceIncrementBy,
  disabled,
  disableControls,
  disableKeyboardInput,
  prefix,
  steps,
  value,
  min,
  max,
  onTextChange,
  onIncrement,
  onDecrement,
  onBlur,
}: Props) {
  const minValue = min || 0
  const maxValue = max

  const INCREMENT = forceIncrementBy
    ? forceIncrementBy
    : // NOTE: incrementBy can be further expanded
    // in the future for better reusability
    incrementBy && value <= 25
    ? incrementBy
    : !dynamicIncrement && steps
    ? steps
    : !dynamicIncrement
    ? 25
    : dynamicIncrement && value < 1000
    ? 250
    : dynamicIncrement && value < 5000
    ? 500
    : 1000

  const formattedValue = disableFormatting ? value : formatValue(value)
  const displayValue = prefix ? prefix + ' ' + formattedValue : formattedValue

  return (
    <div
      id={id}
      className={classnames(
        className ? `InputNumber ${className}` : 'InputNumber',
        disableControls ? 'inputDisabled' : '',
      )}
    >
      {!disabled && !disableControls && (
        <button
          id={`${id}-decrement`}
          className="button decrement"
          disabled={disabled || value <= minValue}
          onClick={() => {
            // round up to nearest increment value
            const roundedValue = Math.ceil(value / INCREMENT) * INCREMENT
            onDecrement(roundedValue > INCREMENT ? roundedValue - INCREMENT : 0)
          }}
        >
          –
        </button>
      )}
      <input
        id={`${id}-input`}
        type="text"
        disabled={disabled || disableKeyboardInput}
        maxLength={11}
        min={minValue}
        value={displayValue}
        className={prefix ? 'with-prefix' : null}
        onBlur={(event: Object) => {
          if (!disableControls) {
            onBlur ? onBlur(validateNumber(event.target.value)) : null
          }
        }}
        onChange={(event: Object) => {
          if (!disableControls) {
            const currentValue = validateNumber(event.target.value)
            onTextChange ? onTextChange(currentValue) : null
          }
        }}
      />
      {!disabled && !disableControls && (
        <button
          id={`${id}-increment`}
          className="button"
          disabled={disabled || value >= maxValue}
          onClick={() => {
            // round down to nearest increment value
            const roundedValue = Math.floor(value / INCREMENT) * INCREMENT
            const incrementedValue = roundedValue + INCREMENT
            onIncrement(incrementedValue > MAX ? MAX : incrementedValue)
          }}
        >
          +
        </button>
      )}
    </div>
  )
}

export default InputNumber
