import { Component } from 'react'

class WebSignatureSuccess extends Component {
  componentDidMount() {
    // Google Tag Manager tracking
    if (PROD) {
      dataLayer.push({
        event: 'funnel',
        funnelType: 'Unbekannt', //Beim regulären Signup: 'SignUp', beim Mitarbeitersignup: 'Mitarbeiter-SignUp', auf der Seite /id-erfolgreich: 'Unbekannt'
        funnelEntry: 'Unbekannt', // Wenn der User vom Minisignup kommt 'Mini-Signup', ansonsten 'Link'
        funnelProduct: 'Unbekannt', // Hier bitte je nach gewähltem Ziel den entsprechenden Wert einsetzen
        funnelStep: 10, // Sparziel: 1, Sparplan: 2, Risiko: 3, Finanzielle Verhältnisse: 4, Finanzielle Erfahrungen: 5, Ihr Plan, 6, Anlagestruktur: 7, Persönliche Angaben: 8, Abschluss: 9, ID erfolgreich: 10
        funnelStepContent: 'ID erfolgreich', //Siehe funnelStep, Bezeichnungen bitte für die jeweiligen Schritte von dort übernehmen
        funnelStepsTotal: 10,
        funnelVersion: 1,
        conversion: 'id-complete', // Bei Schritt 1: 'signup-start', bei Schritt 9 'signup-complete', bei Schritt 10 'id-complete'
      })
    }
  }

  render() {
    return (
      <div>
        <h1>Ihre Identifikation war erfolgreich!</h1>

        <div className="currentStepContent">
          <div className="content-center-lefttext">
            <p>
              Sie wurden erfolgreich identifiziert und Ihre digital signierten Dokumente wurden erfolgreich übermittelt.
            </p>
            <p>Sobald Ihr Konto eröffnet ist, melden wir uns bei Ihnen.</p>

            <div className="greetings">
              <p>Wir wünschen Ihnen viel Freude beim Sparen und Anlegen mit FINABRO.</p>
              <p>Beste Grüße,</p>
              <p>Ihr FINABRO-TEAM</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WebSignatureSuccess
