import styled from 'styled-components'
import LoadingSpinner from 'components/UI/LoadingSpinner'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`

type Props = {
  text?: string
}

const LoadingScreen = ({ text = 'Einen Moment bitte...' }: Props) => {
  return (
    <Wrapper className="LoadingScreen">
      <LoadingSpinner text={text} />
    </Wrapper>
  )
}

export default LoadingScreen
