// This file is for polyfilling modern browsers only
// For IE polyfills use /assets/js/ie-polyfills.js

// Blob.arrayBuffer()
File.prototype.arrayBuffer = File.prototype.arrayBuffer || polyfilledArrayBuffer
Blob.prototype.arrayBuffer = Blob.prototype.arrayBuffer || polyfilledArrayBuffer

function polyfilledArrayBuffer() {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsArrayBuffer(this)
  })
}
