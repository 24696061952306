import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import AuthService from 'services/api/auth'

// create axios client for API requests
const client = axios.create({
  // baseURL: API_URL,
  baseURL: `${location.origin}/api`,
  headers: { post: { 'Content-Type': 'application/json' } },
})

export interface Response<Data> {
  status: 'ok' | 'error'
  data: Data
}

export interface ResponseCuba<Result> {
  success: boolean
  result: Result
  errorMessages: string[]
}

// export interface ResponseError<Result> {
//   errorMessages: string[]
//   result: Result
//   success: boolean
// }

/**
 * Request Wrapper with default success/error actions
 */
const request = function (options: AxiosRequestConfig): Promise<Response<any>> {
  const onSuccess = async (response: AxiosResponse) => {
    // console.log('response.data', response.data)

    // Session timed out
    if (response.data?.data?.errNum === 201) {
      // logout
      await AuthService.logout()
      localStorage.removeItem('user')
      localStorage.removeItem('accessToken')

      // navigate to Login
      location.assign('/login')
    }

    // if (!response.data.success) {
    //   return response.data
    // } else {
    //   throw Error('fehler123')
    // }

    return response.data
  }

  const onError = (error: AxiosError) => {
    console.error('Request Failed:', error.config)

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status)
      console.error('Data:', error.response.data)
      console.error('Headers:', error.response.headers)
    } else {
      // Something else happened while setting up the request
      console.error('Error Message:', error.message)
    }

    return Promise.reject(error.response || error.message)
  }

  return client(options).then(onSuccess).catch(onError)
}

export default request
