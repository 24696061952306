import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Logo, Footer, color, breakpoint } from '@finabro-ui/components'

const Wrapper = styled.div`
  background: ${color.lightest};
  color: ${color.secondary};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;

  .NoMatch__content {
    padding: 2.5rem 1rem 0;

    @media only screen and (min-width: ${breakpoint.mobileLarge}) {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 2rem 7.5rem;
    }
  }

  h1 {
    font-size: 2rem;
    margin: 1rem 0;

    @media only screen and (min-width: ${breakpoint.mobileLarge}) {
      font-size: 2.5rem;
      margin: 1.5rem 0 2.2rem;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-style: italic;
    letter-spacing: 3px;
    margin-bottom: 0;
    text-transform: uppercase;

    @media only screen and (min-width: ${breakpoint.mobileLarge}) {
      font-size: 1.8rem;
    }

    span {
      color: ${color.primary};
    }
  }

  .button {
    display: block;
    font-size: 1rem;
    margin: 0 auto;

    @media only screen and (min-width: ${breakpoint.mobileLarge}) {
      font-size: 1.1rem;
    }
  }
`

const StyledLogo = styled(Logo)`
  margin: 0 auto 4rem;
  width: 90px;

  @media only screen and (min-width: ${breakpoint.mobileLarge}) {
    margin: 0 auto 6rem;
    width: 105px;
  }
`

const StyledFooter = styled(Footer)`
  display: none;

  /* @media only screen and (min-width: ${breakpoint.mobileLarge}) {
    display: flex;
  } */
`

const NoMatch = () => {
  return (
    <Wrapper>
      <div className="NoMatch__content">
        <StyledLogo />

        <h2>
          Error <span>//</span> 404
        </h2>

        <h1>Hoppla, diese Seite existiert leider nicht</h1>

        <Link className="button button--secondary" to="/">
          Zur Startseite
        </Link>
      </div>

      <StyledFooter />
    </Wrapper>
  )
}

export default NoMatch
