import { Helmet } from 'react-helmet-async'
import { MuiThemeProvider } from '@material-ui/core/styles'

import { defaultSignupResources } from 'resources'
import ProductInfoSidebar from 'components/UI/ProductInfoSidebar/ProductInfoSidebar'
import Footer from 'components/UI/Footer/Footer'
import muiTheme from './signupMuiTheme'

function SignupDocumentsSent() {
  // useEffect(() => {
  //   // TODO: uncomment and add correct url
  //   const { dbid } = this.props.childData
  //   if (dbid) {
  //     sendMail(null, 'ACCOUNT_EMAIL_OPT_IN', null, null, dbid)
  //   }
  // })

  const resources = defaultSignupResources

  return (
    <MuiThemeProvider theme={muiTheme}>
      <div className="Signup view">
        <Helmet>
          <meta name="viewport" content="width=1360" />
        </Helmet>

        <div className="content">
          <div>
            <div className="emailSentAgainTitle">
              <h1>{resources.EmailSentAgain.title}</h1>
              <div className="text-area emailSentAgainDescription">
                <p>{resources.EmailSentAgain.description}</p>
              </div>
            </div>
          </div>

          <ProductInfoSidebar
            hideContents
            showDetails
            currencySign="€"
            resources={resources}
            planParametersVisible={false}
          />
        </div>

        <Footer withContactLinks resources={resources} />
      </div>
    </MuiThemeProvider>
  )
}

export default SignupDocumentsSent
