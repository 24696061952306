import request, { Response } from 'core/request'
import CubaApiRequest from 'core/requestCuba'
import config from 'config/config'

type UserData = any

type SessionResponseErrorData = {
  errNum: number
  error: string
  description: string
  info?: any
}

function login(email: string, password: string): Promise<Response<UserData | SessionResponseErrorData>> {
  return request({
    url: '/session/login',
    method: 'POST',
    data: JSON.stringify({
      username: email.trim(),
      password: password.trim(),
      uuid: config.mandantId,
      client: 'inside',
    }),
  })
}

function loginWithToken(refreshToken: string): Promise<Response<UserData | SessionResponseErrorData>> {
  return request({
    url: '/session/login',
    method: 'POST',
    data: JSON.stringify({
      refresh_token: refreshToken,
      uuid: config.mandantId,
      client: 'inside',
    }),
  })
}

function authenticate(): Promise<Response<UserData | SessionResponseErrorData>> {
  return request({
    url: `/session?ts=${Date.now()}`,
    method: 'GET',
  })
}

// function authenticateAdmin(nextState: Object, replace: () => void): void {
//   return request({ url: `/session` })
// }

function logout(): Promise<Response<Record<string, never>>> {
  return request({
    method: 'POST',
    url: '/session/logout',
  })
}

function resetPassword(email: string): Promise<Response<{ email: string; secret: string }>> {
  return request({
    method: 'POST',
    url: '/request/create',
    data: JSON.stringify({ email: email.trim() }),
  })
}

function checkPasswordReset(email: string, secret: string): Promise<Response<Record<string, never>>> {
  return request({
    url: '/request',
    params: {
      email: email.trim(),
      secret,
    },
  })
}

function updatePassword(email: string, secret: string, password: string): Promise<Response<Record<string, never>>> {
  return request({
    method: 'POST',
    url: '/request/update',
    data: JSON.stringify({
      email: email.trim(),
      secret,
      password,
    }),
  })
}

function verify(id: string, newsletterSubscribed: boolean): Promise<{ refresh_token: string }> {
  return CubaApiRequest('/services/maindata_SignUpService/verify', {
    sourceIp: '',
    userSessionId: id,
    agreedNewsletter: newsletterSubscribed,
  })
}

const AuthService = {
  authenticate,
  // authenticateAdmin,
  login,
  loginWithToken,
  logout,
  checkPasswordReset,
  resetPassword,
  updatePassword,
  verify,
}

export default AuthService
