import { useState, useEffect, useRef, ChangeEvent, ReactNode } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import InputAdornment from '@material-ui/core/InputAdornment'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { TextField, LegacyButton as Button, color, breakpoint, isAdmin } from '@finabro-ui/components'

import AuthService from 'services/api/auth'
import SignupAPIService from 'services/api/signup'
import { defaultInsideResources as resources } from 'resources'

import { Wrapper, Column, Title } from 'components/Authentication/Auth'
import Modal from 'components/UI/Modal'
import LoadingSpinner from 'components/UI/LoadingSpinner'

type LoginSuccessData = {
  adminMode: boolean
  userData: any
}

type LoginErrorData = {
  error: string
  errNum: number
  errDescription: string
  info: any
}

const Form = styled.form`
  margin-top: 2rem;
`

const ForgotPasswordLink = styled.div`
  && {
    color: ${color.mediumdark};
    cursor: pointer;
    font-size: 0.8rem;
    letter-spacing: 0.05em;
    text-align: center;
    text-decoration: underline;
  }
`

const ForgotPasswordLink2 = styled.span`
  color: ${color.secondary};
  cursor: pointer;
  text-decoration: underline;
`

const StyledButton = styled(Button)`
  margin: 20px 0;
  width: 100%;

  @media only screen and (min-width: ${breakpoint.mobileLarge}) {
    max-width: 250px;
  }
`

const StyledLink = styled.div`
  color: ${color.mediumdark};
  font-size: 0.75rem;
  text-decoration: none;

  a {
    color: ${color.mediumdark};
  }
`

const StyledIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    fill: ${color.dark};
  }
`

const Login = () => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [showResumeModal, setShowResumeModal] = useState(false)
  const [resumeModalText, setResumeModalText] = useState<ReactNode>('')

  const emailRef = useRef<HTMLInputElement>()

  useEffect(() => {
    const loginWithRefreshToken = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      const refreshToken = urlParams.get('token')
      window.history.replaceState({}, document.title, '/login')
      if (refreshToken) {
        // if we have an token in the URL params, use it and login automatically
        setIsLoading(true)
        localStorage.removeItem('user')
        localStorage.removeItem('accessToken')
        // Using an accessToken directly is not possible, because then we don't have
        // a session created in the old backend. So we have to use the refresh token.
        try {
          const response = await AuthService.loginWithToken(refreshToken)

          if (response.status === 'ok') {
            if (response.data.accessToken) {
              localStorage.setItem('accessToken', response.data.accessToken)
            }

            history.push('/inside-area')
          } else {
            console.error(response.data)
            history.push('/login')
          }
        } catch (error) {
          console.error(error)
          setError(error.data.data.error)
          history.push('/login')
        }
      }
    }
    loginWithRefreshToken()

    emailRef?.current?.focus()
  }, [])

  const handleForgotPassword = () => {
    history.push('/passwort-vergessen', { email })
  }

  async function onSubmit(): Promise<void> {
    if (email && password) {
      setIsLoading(true)
      setError(null)

      try {
        const response = await AuthService.login(email, password)

        if (response.status === 'ok') {
          onLoginSuccess({
            adminMode: isAdmin(response.data.role),
            userData: response.data,
          })
        } else {
          // TODO: error handling
          console.error('response error: ', response.data.error)

          onLoginError({
            error: response.data.error,
            errNum: response.data.errNum,
            errDescription: response.data.description,
            info: response.data.info,
          })
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      setError(resources.login.emptyError)
      setIsLoading(false)
    }
  }

  function onLoginSuccess(data: LoginSuccessData): void {
    if (data.adminMode) {
      history.push('/service-area/uebersicht')
    } else {
      if (data.userData.accessToken) {
        // TODO: store refreshToken in Cookie
        // localStorage.setItem('refreshToken', data.userData.refreshToken)
        localStorage.setItem('accessToken', data.userData.accessToken)
      }

      history.push('/inside-area')

      // funnel tracking for google analytics
      if (PROD) {
        dataLayer.push({
          event: 'user-login',
        })
      }
    }
  }

  function onLoginError(data: LoginErrorData): void {
    // errNum 411 / status 1 -> resume signup
    // errNum 412 or 413 / status 2 or 3 -> show info text
    // errNum 414 / status 4 -> show other info text
    // errNum 427 -> KLV User locked out
    // errNum 428 -> AGBs not confirmed
    // status 5, 6, undefined -> show error
    const errNum = data.errNum

    if (errNum === 404) {
      // redirect to inside v2
      const accessToken = data.info.accessToken
      location.assign(`${SIGNUP_URL}/auth/login?accessToken=${accessToken}`)
    } else if (errNum === 411 && email) {
      SignupAPIService.sendResumeMail(email).then((data: { status: 'ok' | 'error' }) => {
        if (data.status !== 'ok') {
          setError(resources.login.invalidError)
          setIsLoading(false)
        } else {
          setShowResumeModal(true)
          setResumeModalText(resources.login.resumeModalText.text1)
          setIsLoading(false)
        }
      })
    } else if (errNum === 412 || errNum === 413) {
      setShowResumeModal(true)
      setResumeModalText(
        <div>
          {resources.login.resumeModalText.text2}{' '}
          <a href={'mailto:' + resources.login.resumeModalText.email}>{resources.login.resumeModalText.email}</a>
        </div>,
      )
      setIsLoading(false)
    } else if (errNum === 414) {
      setShowResumeModal(true)
      setResumeModalText(resources.login.resumeModalText.text3)
      setIsLoading(false)
    } else if (errNum === 420) {
      setError(resources.login.deactivatedError)
      setIsLoading(false)
    } else if (errNum === 427) {
      setShowResumeModal(true)
      setResumeModalText(<div dangerouslySetInnerHTML={{ __html: resources.login.resumeModalText.text4 }} />)
      setIsLoading(false)
    } else if (errNum === 428) {
      setShowResumeModal(true)
      setResumeModalText(
        <>
          <div dangerouslySetInnerHTML={{ __html: resources.login.resumeModalText.text5 }} />
          <div className="buttonContainer">
            <Link to="/signup" className="button">
              Zur Registrierung
            </Link>
          </div>
        </>,
      )
      setIsLoading(false)
    } else {
      setError(data.errDescription)
      setIsLoading(false)
    }
  }

  return (
    <Wrapper>
      <Column>
        <Title>{resources.login.title}</Title>

        {!isLoading ? (
          <>
            <Form onSubmit={onSubmit}>
              <TextField
                id="email"
                label={resources.login.email.label}
                type="email"
                value={email}
                autoComplete="email"
                margin="normal"
                tabIndex={1}
                inputRef={emailRef}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
              />

              <TextField
                id="password"
                label={resources.login.password.label}
                type={showPassword ? 'text' : 'password'}
                value={password}
                autoComplete="current-password"
                margin="normal"
                tabIndex={2}
                helperText={
                  password && (
                    <ForgotPasswordLink2 id="link-forgotPassword-2" onClick={handleForgotPassword}>
                      Passwort vergessen?
                    </ForgotPasswordLink2>
                  )
                }
                endAdornment={
                  <>
                    {password && (
                      <InputAdornment position="end">
                        <StyledIcon onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </StyledIcon>
                      </InputAdornment>
                    )}

                    {!password && (
                      <InputAdornment position="end">
                        <ForgotPasswordLink id="link-forgotPassword" onClick={handleForgotPassword}>
                          {resources.login.forgotPassword}
                        </ForgotPasswordLink>
                      </InputAdornment>
                    )}
                  </>
                }
                onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                onSubmit={onSubmit}
              />

              {error && <p className="error">{error}</p>}

              <StyledButton id="button-login" isDisabled={!email || !password}>
                Anmelden
              </StyledButton>
            </Form>

            <StyledLink>
              Noch kein Konto?{' '}
              <a href={`${SIGNUP_URL}/registrieren`} target="_blank">
                Jetzt registrieren
              </a>
            </StyledLink>

            <Modal contentLabel="Info" isOpen={showResumeModal} onRequestClose={() => setShowResumeModal(false)}>
              <div>{resumeModalText}</div>
            </Modal>
          </>
        ) : (
          <div className="container">
            <LoadingSpinner text="Sie werden angemeldet..." />
          </div>
        )}
      </Column>
    </Wrapper>
  )
}

export default Login
