import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { isAdmin } from '@finabro-ui/components'

import AuthService from 'services/api/auth'
import { fetchUserData } from 'actions/user'
import { fetchAdminData } from 'actions/admin'
import LoadingScreen from 'components/UI/LoadingScreen'

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const dispatch = useDispatch()
  const [isAuthenticated, setAuthenticated] = useState(null)

  const authenticate = useCallback(async () => {
    try {
      const response = await AuthService.authenticate()

      if (response.status === 'ok') {
        // TODO: move this logic to component level?
        if (isAdmin(response.data.role)) {
          dispatch(fetchAdminData(response.data))
          // redirect to admin?
        } else {
          dispatch(fetchUserData(response.data))
          // redirect to inside?
        }

        setAuthenticated(true)
      } else {
        setAuthenticated(false)
      }
    } catch (error) {
      console.error(error)
      setAuthenticated(false)
    }
  }, [dispatch])

  useEffect(() => {
    authenticate()
  }, [authenticate])

  if (isAuthenticated === null) {
    return <LoadingScreen />
  } else {
    return <Route {...rest}>{isAuthenticated ? children : <Redirect to="/login" />}</Route>
  }
}

export default PrivateRoute
