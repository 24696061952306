import { Message } from 'types'
import { Action } from 'actions/types'
import { Partner } from './main'

export type User = {
  id: number
  kundennr: number
  vorname: string
  nachname: string
  fullname: string
  anrede: string
  titel: string
  titel2: string
  geburtsDatum: string
  geburtsLand: string
  geburtsOrt: string
  nationalitaet: string
  familienStand: string
  strasse: string
  plz: number | string
  ort: string
  land: string
  telefon: string
  email: string
  unternehmen?: string
  // status === 1 ('Signup abgebrochen')
  // status === 2 ('Dok an Kunde schicken')
  // status === 3 ('Dok bei Kunde')
  // status === 4 ('Dok bei Partner')
  // status === 5 ('Konto eröffnet')
  status: number
  benutzerstatus: Object
  memberSince: string
  lastLogin: string
  // NOTE: bei jedem neuen Login wird zwischen 'FINABRO Modellrechnung'
  // und 'Gesetzlicher Modellrechnung' gewechselt
  showFinabroModel: boolean
  konto?: UserKonto
  signupType: {
    id: 0 | 1
  }
  role: 0 | 1 | 2 | 4 | 5 | 6 | 7 | 8 | 9
}

type UserKonto = {
  id: number
  nummer: string
  name: string
  curr: string
  institut: string
  iban: string
  bic: string
}

export type Product = {
  id: number
  product: {
    productId: string
    name: string
    productType: string
    defaultCustomName: string
    tariffName: string
    tariffUrl: string
    profile: string
  }
  name: string
  start: string
  ende: string
  partner: Partner
  mantel: string
  produktmantel: {
    id: number
  }
  kategorie: string
  produktkategorie: any
  portfoliorisiko: number
  status: string
  waehrung: string
  zielSumme: number
  zielRente: number
  bewertungsdatum: string
  gesamtwert: number
  depotwert: number
  kontostand: number
  gewinn: number
  rendite: number
  sumEinzahlungen: number
  musterPortfolio: Portfolio
  konto: Konto
  depot: Depot
  allocation: Array<Allocation>
  allocationGroup: Array<Allocation>
  performance: Array<Performance>
  sparplan: SavingsPlan
  sparplaene: Array<SavingsPlan>
  beneficiary?: Object
  sammelkonto?: boolean
  vertragsOrt?: string
  typ?: string

  productId?: string
  employerProductId?: string
  consumerProductId?: string
  consumerProductStatus?: string
  consumerProductDocuments?: [
    {
      fileState: 'UNSIGNED' | 'SIGNED'
      fileType: string
      downloadLink: string
    }[],
  ]
  productName?: string
  productType?: 'ERHOEHUNG' | 'UMWANDLUNG'
  productProvider?: {
    name: string
    key: string
    downloadLinkLogo: string
    countryIso3: string
  }
  variant: string
  customName?: string
  monthlyAmount?: string
  dateValueDtoList?: []

  capitalFormingBenefit: number
  capitalFormingBenefitChoice: string
  capitalFormingBenefitType: string
  productDynamicChoice: string
  productDynamicType: string
  productDynamicAmount: number
  employerBonusPercentage: number
  employerContribution: number
  productCancelledDate: string
  signatureStepEnabled: boolean
  employer?: {
    sequentialEmployerId: string
    name: string
    registeredOfficeCountry: string
    downloadLinkLogo: string
  }
  signupType: string
  campaignId: string
}

// export type CubaProduct = {
//   consumerProductId: string
//   employerProductId: string
//   productId: string
//   consumerProductStatus: 'PRECONTRACT_INFORMATION_CONFIRMED'
//   variant: 'Gehaltsumwandlung'
//   productType: 'UMWANDLUNG'
//   monthlyAmount: string
//   consumerProductDocuments: [
//     {
//       fileState: 'UNSIGNED'
//       fileType: 'PRE_CONTRACTUAL_INFORMATION_PROVIDER'
//       downloadLink: string
//     }[],
//   ]
//   customName: string
//   productName: string
//   productProvider: {
//     name: string
//     key: string
//     downloadLinkLogo: string
//   }
//   dateValueDtoList: []
// }

export type Portfolio = {
  id: number
  name: string
  targetReturn: number
  expNetReturn: number
  expHighNetReturn: number
  expLowNetReturn: number
  expVolatility: number
  managementFee: number
  srri: Risiko
  risk: Risiko
}

export type Risiko = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

type Konto = {
  id: number
  nummer: string
  name: string
  curr: string
  iban: string
  bic: string
  transaktionen: Transaction[]
}

export type Transaction = {
  id: number
  datum: string
  valuta: string
  typ: string
  text: string
  displaytext: string
  betrag: number
}

export type Depot = {
  id: number
  nummer: string
  name: string
  curr: string
  trades: Trade[]
  positions: Position[]
  valuationDate: string
}

type Trade = {
  id: number
  date: string
  code: string
  name: string
  side: string
  size: number
  price: number
}

export type Position = {
  code: string
  name: string
  klasse: string
  kategorie: string
  size: number
  entry: number
  price: number
  curr: string
  profit: number
  value: number
  valueEUR: number
  alloc: number
  url: string
}

export type Allocation = {
  name: string
  value: number
  alloc: number
}

export type Performance = number[]

export type SavingsPlan = {
  id: number
  start: string
  ende: string
  typ: string
  typid: number
  interval: number
  betrag: number
  wachstumsRate: number
}

export type ScenarioData = {
  allocation: Record<string, unknown>[]
  allocDetails: Record<string, unknown>[]
  portfolio: string
  srri: Risiko
  typ: string
  columns: string[]
  series: number[][]
  sparplan: Object
}

type State = {
  data: User
  products: Product[]
  messages: Message[]
  employerCountry: string
}

const initialState: State = {
  data: {
    id: 0,
    vorname: '',
    nachname: '',
    fullname: '',
    anrede: '',
    titel: '',
    titel2: '',
    geburtsDatum: '',
    geburtsLand: '',
    geburtsOrt: '',
    nationalitaet: '',
    familienStand: '',
    strasse: '',
    plz: '',
    ort: '',
    land: '',
    telefon: '',
    email: '',
    memberSince: '',
    lastLogin: '',
    showFinabroModel: true,
    konto: {
      id: 0,
      nummer: '',
      name: '',
      curr: '',
      institut: '',
      iban: '',
      bic: '',
    },
  },
  products: [],
  messages: [],
  employerCountry: '',
}

export default function userReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'FETCH_USER_DATA':
      return fetchUserData(state, action)

    case 'FETCH_INITIAL_USER':
      return fetchInitialUser(state, action)

    case 'FETCH_USER_PRODUCTS':
      return fetchUserProducts(state, action)

    case 'UPDATE_USER_PRODUCT':
      return updateUserProduct(state, action)

    case 'UPDATE_EMPLOYER_COUNTRY':
      return updateEmployerCountry(state, action)

    case 'FETCH_USER_MESSAGES':
      return fetchUserMessages(state, action)

    case 'SET_MESSAGE_READ':
      return setMessageRead(state, action)

    case 'LOGOUT':
      return initialState

    default:
      return state
  }
}

// reducers

// function fetchUserData(state, action): State {
//   const data = action.payload
//   const fullname =
//     data.vorname && data.nachname
//       ? `${data.vorname} ${data.nachname}`
//       : data.user_vorname && data.user_nachname
//       ? `${data.user_vorname} ${data.user_nachname}`
//       : ''

//   return {
//     ...state,
//     data: {
//       ...data,
//       fullname,
//     },
//   }
// }

function fetchUserData(state, action): State {
  return {
    ...state,
    data: {
      ...action.payload,
      fullname: `${action.payload.vorname} ${action.payload.nachname}`,
    },
  }
}

function fetchInitialUser(state, action): State {
  const { userData, userProducts } = action.payload
  const fullname =
    userData.vorname && userData.nachname
      ? `${userData.vorname} ${userData.nachname}`
      : userData.user_vorname && userData.user_nachname
      ? `${userData.user_vorname} ${userData.user_nachname}`
      : ''

  return {
    ...state,
    data: {
      ...userData,
      fullname: fullname,
    },
    products: userProducts,
  }
}

function fetchUserProducts(state, action): State {
  return {
    ...state,
    products: action.payload,
  }
}

function updateEmployerCountry(state, action): State {
  return {
    ...state,
    employerCountry: action.payload,
  }
}

function updateUserProduct(state, action): State {
  return {
    ...state,
    products: state.products.map((product: Product) => {
      if (product.id === action.payload.id || product.consumerProductId === action.payload.id) {
        return {
          ...product,
          ...action.payload.productData,
        }
      }
      return product
    }),
  }
}

function fetchUserMessages(state, action): State {
  return {
    ...state,
    messages: action.payload,
  }
}

function setMessageRead(state, action): State {
  return {
    ...state,
    messages: state.messages.map((msg) => {
      if (msg.id.toString() === action.payload) {
        return {
          ...msg,
          readdate: 'now',
        }
      }
      return msg
    }),
  }
}
