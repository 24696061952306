import request from 'core/request'
import config from 'config/config'

// SIGNUP - Signup Flow starten

/** API Call POST Parameter:
 * email ..... Benutzer Email Adresse
 * uuid ...... Optionale UUID (um unterbrochenen SignUp Flow fortzusetzen)
 */

export function start(email: string, uuid?: string, signupData?: Object) {
  const params = JSON.stringify({ email, uuid, ...signupData, mandant: config.mandantKey })

  return request({
    method: 'POST',
    url: '/signup/start',
    data: params,
  })
}

// SIGNUP - Signup Flow Daten aktualisieren

/** API Call POST Parameter:
 * email ..... Benutzer Email Adresse
 * uuid ...... Optionale UUID (um unterbrochenen SignUp Flow fortzusetzen)
 * signupData ..... unzaehlige Parameter
 */

export function update(email: string, uuid: string, signupData: Object) {
  const params = JSON.stringify({ email, uuid, ...signupData })

  return request({
    method: 'POST',
    url: '/signup/update',
    data: params,
  })
}

export function resume(email: string, secret: string): Promise {
  return request(`/signup/values?email=${encodeURIComponent(email)}&uuid=${secret}`)
}

export function sendResumeMail(email: string): Promise {
  return request({
    url: '/signup/resume',
    method: 'POST',
    data: { email: email.trim(), expiry: 86400000 },
  })
}

// SIGNUP - Signup Flow abschließen

/** API Call POST Parameter:
 * email ..... Benutzer Email Adresse
 * uuid ...... Aktuelle UUID
 */

export function finish(email: string, uuid: string) {
  const params = JSON.stringify({ email, uuid })

  return request({
    method: 'POST',
    url: '/signup/finish',
    data: params,
  })
}

const SignupAPIService = {
  start,
  update,
  sendResumeMail,
  resume,
  finish,
}

export default SignupAPIService
