module.exports = {
  title: 'FINABRO',
  themeId: 'finabro',
  insideURL: 'https://inside.finabro.at',
  gtmId: 'GTM-K3VKM4Z',
  mandantId: '418e6f70-2225-4f7e-a2d9-4815f9763909',
  mandantKey: 'finabro',
  logoLink: 'https://www.finabro.at',
  hiddenCategories: [],
  webIdClientName: 'FINABRO GmbH',
}
