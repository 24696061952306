class HelpBoxContentService {
  static INSTANCE = new HelpBoxContentService()

  static getInstance() {
    return HelpBoxContentService.INSTANCE
  }

  constructor() {
    this.content = {}
    this.listeners = []
  }

  reset() {
    this.setContent({})
  }

  setContent(content) {
    // console.log('HelpBoxContentService: Setting helpbox content to ', content)
    this.content = content
    this.listeners.forEach((l) => l(content))
  }

  setContentFromResources(resources, actionHandler) {
    if (resources && resources.helpbox) {
      const content = resources.helpbox
      if (content.items && actionHandler) {
        content.items.forEach((item, index) => {
          if ((item) => item.type === 'button' && item.actionId) {
            // eslint-disable-line
            content.items[index] = { ...item, action: () => actionHandler(item.actionId) }
          }
        })
      }
      this.setContent(content)
    } else {
      this.reset()
    }
  }

  getContent() {
    return this.content
  }

  registerListener(listener) {
    this.listeners.push(listener)
  }
}

export default HelpBoxContentService
