import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import EditIcon from '@material-ui/icons/Edit'
import MailIcon from '@material-ui/icons/Mail'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import {
  ProgressSteps,
  ProgressStepsItem,
  LegacyButton as Button,
  breakpoint,
  typography,
} from '@finabro-ui/components'

import { Wrapper, Column, Title } from 'components/Authentication/Auth'

const StyledProgressSteps = styled(ProgressSteps)`
  margin-top: 1rem;

  @media only screen and (min-width: ${breakpoint.mobileLarge}) {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
  }
`

const StyledProgressStepsItem = styled(ProgressStepsItem)`
  min-width: 200px;
  max-width: none;
`

const Text = styled.div`
  font-size: ${typography.size.s2}px;
  margin-top: 4rem;
  margin-bottom: 2rem;

  ul {
    margin-left: 2rem;
    text-align: left;
  }
`

const StyledButton = styled(Button)`
  margin: 20px 0;
  width: 100%;

  @media only screen and (min-width: ${breakpoint.mobileLarge}) {
    max-width: 250px;
  }
`

const PasswordResetSuccess = () => {
  const history = useHistory()

  return (
    <Wrapper>
      <Column>
        <Title>Es ist fast geschafft!</Title>

        <StyledProgressSteps activeIndex={0}>
          <StyledProgressStepsItem icon={<MailIcon />} label="Sie haben von uns eine <br> E-Mail erhalten" />
          <StyledProgressStepsItem
            icon={<ExitToAppOutlinedIcon />}
            label="Bitte klicken Sie auf <br> den Link in der E-Mail"
          />
          <StyledProgressStepsItem icon={<EditIcon />} label="Vergeben Sie ein <br> neues Passwort" />
        </StyledProgressSteps>

        <Text>
          Mögliche Gründe für ein Ausbleiben der E-Mail:
          <ul>
            <li>Spamordner im E-Mail Postfach</li>
            <li>Tippfehler bei der Eingabe der E-Mail</li>
          </ul>
          Kontaktieren Sie bei weiteren Problemen gerne unseren <a href="mailto:service@finabro.at">Kundenservice</a>.
        </Text>

        <StyledButton id="link-login" onClick={() => history.push('/login')}>
          Zurück zum Login
        </StyledButton>
      </Column>
    </Wrapper>
  )
}

export default PasswordResetSuccess
