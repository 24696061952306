import type { ReactNode } from 'react'
import ReactModal from 'react-modal'

type Props = {
  className?: string
  contentLabel?: string
  isOpen?: boolean
  hideCloseButton?: boolean
  onRequestClose?: () => void
  children?: ReactNode
}

ReactModal.setAppElement('#app')

function Modal({ className, contentLabel, isOpen, hideCloseButton, onRequestClose, children, ...props }: Props) {
  return (
    <ReactModal
      className={`Modal ${className}`}
      overlayClassName="Modal__overlay"
      contentLabel={`Info ${contentLabel}`}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      {...props}
    >
      {!hideCloseButton && <img className="Modal__close" src="/assets/images/UI/close.png" onClick={onRequestClose} />}

      {children}
    </ReactModal>
  )
}

export default Modal
