export const COUNTRIES = [
  'Afghanistan',
  'Ägypten',
  'Aland',
  'Albanien',
  'Algerien',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarktis',
  'Argentinien',
  'Armenien',
  'Aruba',
  'Ascension',
  'Aserbaidschan',
  'Äthiopien',
  'Australien',
  'Bahamas',
  'Bahrain',
  'Bangladesch',
  'Barbados',
  'Belgien',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivien',
  'Bosnien und Herzegowina',
  'Botswana',
  'Bouvetinsel',
  'Brasilien',
  'Brunei',
  'Bulgarien',
  'Burkina Faso',
  'Burundi',
  'Chile',
  'China',
  'Cookinseln',
  'Côte d’Ivoire',
  'Costa Rica',
  'Dänemark',
  'Dominica',
  'Dominikanische Republik',
  'Dschibuti',
  'Ecuador',
  'El Salvador',
  'Eritrea',
  'Estland',
  'Färöer',
  'Fidschi',
  'Finnland',
  'Frankreich',
  'Gabun',
  'Gambia',
  'Georgien',
  'Ghana',
  'Gibraltar',
  'Grenada',
  'Griechenland',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hongkong',
  'Indien',
  'Indonesien',
  'Irak',
  'Iran',
  'Irland',
  'Island',
  'Isle of Man',
  'Israel',
  'Italien',
  'Jamaika',
  'Japan',
  'Jemen',
  'Jersey',
  'Jordanien',
  'Kaimaninseln',
  'Kambodscha',
  'Kamerun',
  'Kanada',
  'Kanarische Inseln',
  'Kap Verde',
  'Kasachstan',
  'Katar',
  'Kenia',
  'Kirgisistan',
  'Kiribati',
  'Kokosinseln',
  'Kolumbien',
  'Komoren',
  'Kongo',
  'Kroatien',
  'Kuba',
  'Kuwait',
  'Laos',
  'Lesotho',
  'Lettland',
  'Libanon',
  'Liberia',
  'Libyen',
  'Liechtenstein',
  'Litauen',
  'Luxemburg',
  'Macao',
  'Madagaskar',
  'Malawi',
  'Malaysia',
  'Malediven',
  'Mali',
  'Malta',
  'Marokko',
  'Marshallinseln',
  'Martinique',
  'Mauretanien',
  'Mauritius',
  'Mayotte',
  'Mazedonien',
  'Mexiko',
  'Moldawien',
  'Monaco',
  'Mongolei',
  'Montenegro',
  'Montserrat',
  'Mosambik',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Neukaledonien',
  'Neuseeland',
  'Nicaragua',
  'Niederlande',
  'Niger',
  'Nigeria',
  'Niue',
  'Nordkorea',
  'Norfolkinsel',
  'Norwegen',
  'Oman',
  'Osttimor',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua-Neuguinea',
  'Paraguay',
  'Peru',
  'Philippinen',
  'Pitcairninseln',
  'Polen',
  'Portugal',
  'Puerto Rico',
  'Ruanda',
  'Rumänien',
  'Russische Föderation',
  'Salomonen',
  'Sambia',
  'Samoa',
  'San Marino',
  'Saudi-Arabien',
  'Schweden',
  'Schweiz',
  'Senegal',
  'Serbien',
  'Serbien und Montenegro',
  'Seychellen',
  'Sierra Leone',
  'Simbabwe',
  'Singapur',
  'Slowakei',
  'Slowenien',
  'Somalia',
  'Sowjetunion',
  'Spanien',
  'Sri Lanka',
  'St. Kitts und Nevis',
  'St. Lucia',
  'St. Pierre und Miquelon',
  'St. Vincent und die Grenadinen',
  'Südafrika',
  'Sudan',
  'Südkorea',
  'Suriname',
  'Swasiland',
  'Syrien',
  'Tadschikistan',
  'Taiwan',
  'Tansania',
  'Thailand',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad und Tobago',
  'Tristan da Cunha',
  'Tschad',
  'Tschechien',
  'Tunesien',
  'Türkei',
  'Turkmenistan',
  'Uganda',
  'Ukraine',
  'Ungarn',
  'Uruguay',
  'Usbekistan',
  'Vatikanstadt',
  'Venezuela',
  'Vereinigte Arabische Emirate',
  'Vereinigte Staaten',
  'Vereinigtes Königreich',
  'Vietnam',
  'Wallis und Futuna',
  'Weihnachtsinsel',
  'Weißrussland',
  'Zentralafrikanische Republik',
  'Zypern',
]

export const OTHER_TAX_COUNTRIES = [
  'Australien',
  'Bahamas',
  'Belgien',
  'Chile',
  'Dänemark',
  'Estland',
  'Finnland',
  'Frankreich',
  'Georgien',
  'Großbritannien',
  'Guernsey',
  'Hongkong',
  'Insel Man',
  'Irland',
  'Israel',
  'Italien',
  'Japan',
  'Jersey',
  'Kanada',
  'Katar',
  'Lettland',
  'Liechtenstein',
  'Litauen',
  'Luxemburg',
  'Malta',
  'Mauritius',
  'Namibia',
  'Neuseeland',
  'Niederlande',
  'Norwegen',
  'Österreich',
  'Polen',
  'Portugal',
  'Republik Korea',
  'Schweden',
  'Schweiz',
  'Singapur',
  'Slowakei',
  'Slowenien',
  'Spanien',
  'St. Vincent',
  'Taiwan',
  'Tschechische Republik',
  'Vereinigte Arabische Emirate',
  'Zypern',
]

export const COUNTRIES_ISO = {
  Afghanistan: 'af',
  Ägypten: 'eg',
  Aland: 'ax',
  Albanien: 'al',
  Algerien: 'dz',
  Andorra: 'ad',
  Angola: 'ao',
  Anguilla: 'ai',
  Antarktis: 'aq',
  Argentinien: 'ar',
  Armenien: 'am',
  Aruba: 'aw',
  Ascension: 'ac',
  Aserbaidschan: 'az',
  Äthiopien: 'et',
  Australien: 'au',
  Bahamas: 'bs',
  Bahrain: 'bh',
  Bangladesch: 'bd',
  Barbados: 'bb',
  Belgien: 'be',
  Belize: 'bz',
  Benin: 'bj',
  Bermuda: 'bm',
  Bhutan: 'bt',
  Bolivien: 'bo',
  'Bosnien und Herzegowina': 'ba',
  Botswana: 'bw',
  Bouvetinsel: 'bv',
  Brasilien: 'br',
  Brunei: 'bn',
  Bulgarien: 'bg',
  'Burkina Faso': 'bf',
  Burundi: 'bi',
  Chile: 'cl',
  China: 'cn',
  Cookinseln: 'ck',
  'Côte d’Ivoire': 'ci',
  'Costa Rica': 'cr',
  Dänemark: 'dk',
  Deutschland: 'de',
  Dominica: 'dm',
  'Dominikanische Republik': 'do',
  Dschibuti: 'dj',
  Ecuador: 'ec',
  'El Salvador': 'sv',
  Eritrea: 'er',
  Estland: 'ee',
  Färöer: 'fo',
  Fidschi: 'fj',
  Finnland: 'fi',
  Frankreich: 'fr',
  Gabun: 'ga',
  Gambia: 'gm',
  Georgien: 'ge',
  Ghana: 'gh',
  Gibraltar: 'gi',
  Grenada: 'gd',
  Griechenland: 'gr',
  Guadeloupe: 'gp',
  Guam: 'gu',
  Guatemala: 'gt',
  Guernsey: 'gg',
  Guinea: 'gn',
  'Guinea-Bissau': 'gw',
  Guyana: 'gy',
  Haiti: 'ht',
  Honduras: 'hn',
  Hongkong: 'hk',
  Indien: 'in',
  Indonesien: 'id',
  Irak: 'iq',
  Iran: 'ir',
  Irland: 'ie',
  Island: 'is',
  'Isle of Man': 'im',
  Israel: 'il',
  Italien: 'it',
  Jamaika: 'jm',
  Japan: 'jp',
  Jemen: 'ye',
  Jersey: 'je',
  Jordanien: 'jo',
  Kaimaninseln: 'ky',
  Kambodscha: 'kh',
  Kamerun: 'cm',
  Kanada: 'ca',
  'Kanarische Inseln': 'ic',
  'Kap Verde': 'cv',
  Kasachstan: 'kz',
  Katar: 'qa',
  Kenia: 'ke',
  Kirgisistan: 'kg',
  Kiribati: 'ki',
  Kokosinseln: 'cc',
  Kolumbien: 'co',
  Komoren: 'km',
  Kongo: 'cd',
  Kroatien: 'hr',
  Kuba: 'cu',
  Kuwait: 'kw',
  Laos: 'la',
  Lesotho: 'ls',
  Lettland: 'lv',
  Libanon: 'lb',
  Liberia: 'lr',
  Libyen: 'ly',
  Liechtenstein: 'li',
  Litauen: 'lt',
  Luxemburg: 'lu',
  Macao: 'mo',
  Madagaskar: 'mg',
  Malawi: 'mw',
  Malaysia: 'my',
  Malediven: 'mv',
  Mali: 'ml',
  Malta: 'mt',
  Marokko: 'ma',
  Marshallinseln: 'mh',
  Martinique: 'mq',
  Mauretanien: 'mr',
  Mauritius: 'mu',
  Mayotte: 'yt',
  Mazedonien: 'mk',
  Mexiko: 'mx',
  Moldawien: 'md',
  Monaco: 'mc',
  Mongolei: 'mn',
  Montenegro: 'me',
  Montserrat: 'ms',
  Mosambik: 'mz',
  Myanmar: 'mm',
  Namibia: 'na',
  Nauru: 'nr',
  Nepal: 'np',
  Neukaledonien: 'nc',
  Neuseeland: 'nz',
  Nicaragua: 'ni',
  Niederlande: 'nl',
  Niger: 'ne',
  Nigeria: 'ng',
  Niue: 'nu',
  Nordkorea: 'kp',
  Norfolkinsel: 'nf',
  Norwegen: 'no',
  Oman: 'om',
  Österreich: 'at',
  Osttimor: 'tl',
  Pakistan: 'pk',
  Palau: 'pw',
  Panama: 'pa',
  'Papua-Neuguinea': 'pg',
  Paraguay: 'py',
  Peru: 'pe',
  Philippinen: 'ph',
  Pitcairninseln: 'pn',
  Polen: 'pl',
  Portugal: 'pt',
  'Puerto Rico': 'pr',
  Ruanda: 'rw',
  Rumänien: 'ro',
  'Russische Föderation': 'ru',
  Salomonen: 'sb',
  Sambia: 'zm',
  Samoa: 'ws',
  'San Marino': 'sm',
  'Saudi-Arabien': 'sa',
  Schweden: 'se',
  Schweiz: 'ch',
  Senegal: 'sn',
  Serbien: 'rs',
  'Serbien und Montenegro': 'cs',
  Seychellen: 'sc',
  'Sierra Leone': 'sl',
  Simbabwe: 'zw',
  Singapur: 'sg',
  Slowakei: 'sk',
  Slowenien: 'si',
  Somalia: 'so',
  Sowjetunion: 'su',
  Spanien: 'es',
  'Sri Lanka': 'lk',
  'St. Kitts und Nevis': 'kn',
  'St. Lucia': 'lc',
  'St. Pierre und Miquelon': 'pm',
  'St. Vincent und die Grenadinen': 'vc',
  Südafrika: 'za',
  Sudan: 'sd',
  Südkorea: 'kr',
  Suriname: 'sr',
  Swasiland: 'sz',
  Syrien: 'sy',
  Tadschikistan: 'tj',
  Taiwan: 'tw',
  Tansania: 'tz',
  Thailand: 'th',
  Togo: 'tg',
  Tokelau: 'tk',
  Tonga: 'to',
  'Trinidad und Tobago': 'tt',
  'Tristan da Cunha': 'ta',
  Tschad: 'td',
  Tschechien: 'cz',
  Tunesien: 'tn',
  Türkei: 'tr',
  Turkmenistan: 'tm',
  Uganda: 'ug',
  Ukraine: 'ua',
  Ungarn: 'hu',
  Uruguay: 'uy',
  Usbekistan: 'uz',
  Vatikanstadt: 'va',
  Venezuela: 've',
  'Vereinigte Arabische Emirate': 'ae',
  'Vereinigte Staaten': 'us',
  'Vereinigtes Königreich': 'gb',
  Vietnam: 'vn',
  'Wallis und Futuna': 'wf',
  Weihnachtsinsel: 'cx',
  Weißrussland: 'by',
  'Zentralafrikanische Republik': 'cf',
  Zypern: 'cy',
}
