class ProductMinimumValueService {
  static INSTANCE = new ProductMinimumValueService()

  static getInstance() {
    return ProductMinimumValueService.INSTANCE
  }

  getProductDepositMinValue() {
    return 0
  }

  getProductSavingsRateMinValue() {
    return 0
  }

  getProductDepositThresholdValue() {
    return 500
  }

  getProductSavingsRateThresholdValue() {
    return 25
  }
}

export default ProductMinimumValueService
