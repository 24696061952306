import { Action } from 'actions/types'
import { User } from './user'

type State = {
  data?: User
  clients: User[]
  tasks: string[]
  message: any
  filteredCompany?: string
  filteredIntitute?: string
  filteredStatus?: number
  filteredType?: number
  filteredFulltext?: string
}

const initialState: State = {
  data: null,
  clients: [],
  filteredCompany: '',
  filteredInstitute: '',
  filteredStatus: '',
  filteredType: '',
  filteredFulltext: '',
  tasks: [],
  message: {},
}

export default function adminReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'FETCH_ADMIN_DATA':
      return fetchAdminData(state, action)

    case 'FETCH_INITIAL_ADMIN':
      return fetchInitialAdmin(state, action)

    case 'FETCH_CUSTOMERS':
      return fetchCustomers(state, action)

    case 'FETCH_TASKS':
      return fetchTasks(state, action)

    case 'FILTER_COMPANY':
      return filterCompany(state, action)

    case 'FILTER_INSTITUTE':
      return filterInstitute(state, action)

    case 'FILTER_STATUS':
      return filterStatus(state, action)

    case 'FILTER_TYPE':
      return filterType(state, action)

    case 'FILTER_FULLTEXT':
      return filterFulltext(state, action)

    case 'DELETE_CLIENT':
      return deleteClient(state, action)

    case 'FETCH_MESSAGES':
      return fetchMessages(state, action)

    case 'FETCH_MESSAGE':
      return fetchMessage(state, action)

    case 'DELETE_MESSAGE':
      return deleteMessage(state, action)

    default:
      return state
  }
}

// reducers

function fetchAdminData(state, action): State {
  return {
    ...state,
    data: action.payload,
  }
}

function fetchInitialAdmin(state, action): State {
  const { adminData, clients } = action.payload

  return {
    ...state,
    data: adminData,
    clients: clients,
  }
}

function fetchCustomers(state, action): State {
  return {
    ...state,
    clients: action.payload,
  }
}

function fetchTasks(state, action): State {
  return {
    ...state,
    tasks: action.payload,
  }
}

function filterCompany(state, action): State {
  return {
    ...state,
    filteredCompany: action.payload,
  }
}

function filterInstitute(state, action): State {
  return {
    ...state,
    filteredInstitute: action.payload,
  }
}

function filterStatus(state, action): State {
  return {
    ...state,
    filteredStatus: action.payload,
  }
}

function filterType(state, action): State {
  return {
    ...state,
    filteredType: action.payload,
  }
}

function filterFulltext(state, action): State {
  return {
    ...state,
    filteredFulltext: action.payload,
  }
}

function deleteClient(state, action): State {
  const index = state.clients.findIndex((client) => client.id === action.payload)

  return {
    ...state,
    clients: [...state.clients.slice(0, index), ...state.clients.slice(index + 1)],
  }
}

function fetchMessages(state, action): State {
  return {
    ...state,
    messages: action.payload,
  }
}

function fetchMessage(state, action): State {
  return {
    ...state,
    message: action.payload,
  }
}

function deleteMessage(state, action): State {
  const index = state.messages.findIndex((message) => message.id === action.payload)

  return {
    ...state,
    messages: [...state.messages.slice(0, index), ...state.messages.slice(index + 1)],
  }
}
