import LogoIcon from 'components/UI/SVG/LogoFinabro'

type Props = {
  link: string
}

function Logo({ link }: Props) {
  return (
    <div className="Logo">
      <a href={link} target="_blank" rel="noopener">
        <LogoIcon />
      </a>
    </div>
  )
}

export default Logo
