import { Route, Switch, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { Logo, Footer, color, typography, breakpoint } from '@finabro-ui/components'

import {
  Login,
  Logout,
  PasswordReset,
  PasswordResetSuccess,
  PasswordUpdate,
  Verify,
  AccountActivated,
} from 'components/Authentication'

import 'stylesheets/utils/helpers.scss'

const muiTheme = createTheme({
  palette: {
    primary: {
      light: color.secondary,
      main: color.secondary,
      dark: color.secondary,
    },
    error: {
      main: color.danger,
    },
  },
  typography: {
    fontFamily: typography.type.primary,
  },
})

export const Container = styled.div`
  background: ${color.lightest};
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .Auth__content {
    padding: 2.5rem 1rem 0;

    @media only screen and (min-width: ${breakpoint.mobileLarge}) {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem 2rem 7rem;
    }
  }

  @media only screen and (max-width: ${breakpoint.mobileLarge}) {
    .content {
      margin-top: 8px;
      margin-bottom: 15px;
    }
  }
`

export const Wrapper = styled.div`
  padding: 0;
  text-align: center;
`

export const Column = styled.div`
  margin: 0 auto;

  @media only screen and (min-width: ${breakpoint.mobileLarge}) {
    max-width: 400px;
  }
`

const StyledLogo = styled(Logo)`
  margin: 0 auto;
  max-width: 105px;
  width: 105px;

  @media only screen and (min-width: ${breakpoint.mobileLarge}) {
    margin-bottom: 3rem;
  }

  a,
  svg {
    width: 105px;
  }
`

export const Title = styled.h1`
  color: ${color.secondary};
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.01rem;
  margin-top: 13%;
  margin-bottom: 1rem;

  @media only screen and (min-width: ${breakpoint.mobileLarge}) {
    font-size: 1.8rem;
    margin-top: 1rem;
  }
`

export const Subtitle = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const Form = styled.form`
  margin-top: 2rem;
`

export const StyledFooter = styled(Footer)`
  display: none;

  @media only screen and (min-width: ${breakpoint.mobileLarge}) {
    display: flex;
  }
`

const Auth = () => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
      </Helmet>

      <Container>
        <div className="Auth__content">
          <StyledLogo />

          <Switch>
            <Redirect exact from="/" to="/login" />

            <Route path="/login" exact>
              <Login />
            </Route>

            <Route path="/logout" exact>
              <Logout />
            </Route>

            <Route path="/passwort-vergessen" exact>
              <PasswordReset />
            </Route>

            <Route path="/passwort-vergessen/erfolgreich" exact>
              <PasswordResetSuccess />
            </Route>

            <Route path="/passwort-vergessen/:secret" exact>
              <PasswordUpdate />
            </Route>

            <Route path="/verifizieren" exact>
              <Verify />
            </Route>

            {/* NOTE: AccountActivated used? */}
            <Route path="/konto-aktiviert" exact>
              <AccountActivated />
            </Route>

            <Route path="*">
              <Redirect to="/seite-nicht-gefunden" />
            </Route>
          </Switch>
        </div>

        <StyledFooter />
      </Container>
    </MuiThemeProvider>
  )
}

export default Auth
