import { useEffect } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet-async'

import { defaultSignupResources } from 'resources'
import muiTheme from 'containers/Signup/signupMuiTheme'
import Footer from 'components/UI/Footer/index'
import ProductInfoSidebar from 'components/UI/ProductInfoSidebar/index'

import 'containers/Signup/Signup.scss'

type Props = {
  children: Object
}

function WebIdSuccess({ children }: Props) {
  const resources = defaultSignupResources

  useEffect(() => {
    document.body.classList.add('signup')

    // Google Tag Manager tracking
    if (PROD) {
      dataLayer.push({
        event: 'funnel',
        funnelType: 'Unbekannt', //Beim regulären Signup: 'SignUp', beim Mitarbeitersignup: 'Mitarbeiter-SignUp', auf der Seite /id-erfolgreich: 'Unbekannt'
        funnelEntry: 'Unbekannt', // Wenn der User vom Minisignup kommt 'Mini-Signup', ansonsten 'Link'
        funnelProduct: 'Unbekannt', // Hier bitte je nach gewähltem Ziel den entsprechenden Wert einsetzen
        funnelStep: 10, // Sparziel: 1, Sparplan: 2, Risiko: 3, Finanzielle Verhältnisse: 4, Finanzielle Erfahrungen: 5, Ihr Plan, 6, Anlagestruktur: 7, Persönliche Angaben: 8, Abschluss: 9, ID erfolgreich: 10
        funnelStepContent: 'ID erfolgreich', //Siehe funnelStep, Bezeichnungen bitte für die jeweiligen Schritte von dort übernehmen
        funnelStepsTotal: 10,
        funnelVersion: 1,
        conversion: 'id-complete', // Bei Schritt 1: 'signup-start', bei Schritt 9 'signup-complete', bei Schritt 10 'id-complete'
      })
    }
  })

  return (
    <MuiThemeProvider theme={muiTheme}>
      <div className="Signup view">
        <Helmet>
          <meta name="viewport" content="width=1360" />
        </Helmet>

        <div className="content">
          <div className="currentStepContainer">{children}</div>

          <ProductInfoSidebar
            hideContents
            showDetails={false}
            currencySign="€"
            deposit={null}
            productCategories={null}
            targetCategory={null}
            targetAmount={null}
            targetRate={null}
            targetYear={null}
            savingsRate={null}
            planParametersVisible={false}
          />
        </div>

        <Footer withContactLinks resources={resources} />
      </div>
    </MuiThemeProvider>
  )
}

export default WebIdSuccess
