import { Action } from '../actions/types'

export type Partner = {
  id: number
  name: string
  konto: Konto
}

export type Company = {
  id: number
  name: string
  strasse: string
  plz: number
  ort: string
  land: string
}

type Konto = {
  id: number
  nummer: string
  name: string
  curr: string
  institut: string
  iban: string
  bic: string
}

export type ProductCategory = {
  id: number
  name: string
}

export type ProductType = {
  id: number
  name: string
}

export type TransactionType = {
  id: number
  name: string
}

export type UserStatus = {
  id: number
  name: string
}

type State = {
  partners: Partner[]
  productCategories: ProductCategory[]
  productTypes: ProductType[]
  transactionTypes: TransactionType[]
  userStatuses: UserStatus[]
}

const initialState: State = {
  userStatuses: [],
  companies: [],
  partners: [],
  productCategories: [],
  productTypes: [],
  transactionTypes: [],
}

export default function mainReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'FETCH_APP_DATA_ADMIN':
      return fetchAppDataAdmin(state, action)

    case 'FETCH_APP_DATA_USER':
      return fetchAppDataUser(state, action)

    case 'FETCH_PARTNERS':
      return fetchPartners(state, action)

    case 'FETCH_COMPANIES':
      return fetchCompanies(state, action)

    case 'ADD_COMPANY':
      return addCompany(state, action)

    case 'FETCH_PRODUCT_CATEGORIES':
      return fetchProductCategories(state, action)

    case 'FETCH_TRANSACTION_TYPES':
      return fetchTransactionTypes(state, action)

    default:
      return state
  }
}

// reducers
function fetchAppDataAdmin(state, action): State {
  const { productCategories, productTypes, userStatuses } = action.payload

  return {
    ...state,
    productCategories,
    productTypes,
    userStatuses,
  }
}

function fetchAppDataUser(state, action): State {
  const { partners, productCategories } = action.payload

  return {
    ...state,
    partners,
    productCategories,
  }
}

function fetchPartners(state, action): State {
  return {
    ...state,
    partners: action.payload,
  }
}

function fetchCompanies(state, action): State {
  return {
    ...state,
    companies: action.payload,
  }
}

function addCompany(state, action): State {
  return {
    ...state,
    companies: [...state.companies, action.payload],
  }
}

function fetchProductCategories(state, action): State {
  return {
    ...state,
    productCategories: action.payload,
  }
}

function fetchTransactionTypes(state, action): State {
  return {
    ...state,
    transactionTypes: action.payload,
  }
}
