import { Component } from 'react'
import Slider from '@material-ui/core/Slider'
import { formatValue } from '@finabro-ui/components'

type Props = {
  disabled?: boolean
  firstTooltipPositionCentered?: boolean
  fixedValue?: number
  min: number
  max: number
  step: number
  value: number
  valueBottom?: number | string
  width: number
  categories?: Record<string, unknown>[]
  tooltipPostfix?: string
  tooltipPostfixWidth?: number
  tooltipPostfixNormal?: boolean
  minText?: string
  showMinMax?: boolean
  customScale?: boolean
  customMin?: number
  customMax?: number
  customTransform?: (value: number) => number
  customReverse?: (value: number) => number
  onChange: (event: Object, value: number) => void
  minMarginLeft: number
}

type State = {
  sliderWidth: number
  diffMaxMin: number
  lastCategoryLeft: number
  penultimateValue: number
}

const KNOB_WIDTH = 12
const HALF_KNOB_WIDTH = KNOB_WIDTH / 2
const CATEGORY_WIDTH = 96
const HALF_CATEGORY_WIDTH = CATEGORY_WIDTH / 2
const CATEGORY_THRESH = 20
const FIXED_VALUE_INDICATOR_WIDTH = 18
const HALF_FIXED_VALUE_INDICATOR_WIDTH = FIXED_VALUE_INDICATOR_WIDTH / 2
const FIXED_VALUE_LABEL_WIDTH = 105
const HALF_FIXED_VALUE_LABEL_WIDTH = FIXED_VALUE_LABEL_WIDTH / 2
const MINOR_ADJUST = 8
const TOOLTIP_POSTFIX_WIDTH = 60

class StepSlider extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    const { width, max, min, step } = props

    this.state = {
      sliderWidth: width - KNOB_WIDTH,
      diffMaxMin: max - min,
      lastCategoryLeft: width - CATEGORY_WIDTH,
      penultimateValue: max - step,
    }
  }

  render() {
    const {
      disabled,
      firstTooltipPositionCentered,
      fixedValue,
      min,
      max,
      step,
      value,
      valueBottom,
      width,
      categories,
      tooltipPostfix,
      tooltipPostfixWidth,
      tooltipPostfixNormal,
      minText,
      showMinMax,
      onChange,
      customScale,
      customMin,
      customMax,
      customTransform,
      customReverse,
    } = this.props
    const { sliderWidth, diffMaxMin, lastCategoryLeft, penultimateValue } = this.state

    const tooltipPostfixWidthHelper = tooltipPostfixWidth ? tooltipPostfixWidth : TOOLTIP_POSTFIX_WIDTH

    const tooltipLeft = customScale
      ? (sliderWidth * (customReverse(value) - min)) / diffMaxMin
      : (sliderWidth * (value - min)) / diffMaxMin

    let categoryName = undefined
    let categoryLeft = 0

    if (categories && categories.length) {
      categories.some((category) => {
        const hasFound = category.values.indexOf(value) !== -1
        categoryName = hasFound ? category.name : categoryName
        // as soon as a category has been found, stop searching
        return hasFound
      })

      let nextValue = value + step
      nextValue = nextValue < max ? nextValue : max
      const nextTooltipLeft = (sliderWidth * (nextValue - min)) / diffMaxMin

      let prevValue = value - step
      prevValue = prevValue > min ? prevValue : min
      const prevTooltipLeft = (sliderWidth * (prevValue - min)) / diffMaxMin

      categoryLeft = MINOR_ADJUST + tooltipLeft - HALF_CATEGORY_WIDTH

      let tooltipLeftAdjusted = tooltipLeft
      if (prevValue === min && categoryLeft - prevTooltipLeft <= CATEGORY_THRESH && !firstTooltipPositionCentered)
        tooltipLeftAdjusted = prevTooltipLeft

      categoryLeft = MINOR_ADJUST + tooltipLeftAdjusted - HALF_CATEGORY_WIDTH
      categoryLeft = firstTooltipPositionCentered ? categoryLeft : categoryLeft > 0 ? categoryLeft : 0
      categoryLeft = categoryLeft < lastCategoryLeft ? categoryLeft : lastCategoryLeft

      if (value === penultimateValue && Math.abs(categoryLeft - lastCategoryLeft) <= CATEGORY_THRESH)
        categoryLeft = lastCategoryLeft
    }

    // calculations for FIXED_VALUE_INDICATOR and FIXED_VALUE_LABEL
    const fixedValueLeft = fixedValue ? (sliderWidth * (fixedValue - min)) / diffMaxMin : 0
    const fixedValueWidth = width - fixedValueLeft - HALF_FIXED_VALUE_INDICATOR_WIDTH
    const fixedValueMarginLeft = fixedValueLeft - HALF_FIXED_VALUE_INDICATOR_WIDTH - HALF_KNOB_WIDTH
    const fixedLabelWidth = fixedValueWidth + (HALF_FIXED_VALUE_LABEL_WIDTH - HALF_FIXED_VALUE_INDICATOR_WIDTH)
    const fixedLabelMarginLeft =
      fixedValueMarginLeft - (HALF_FIXED_VALUE_LABEL_WIDTH - HALF_FIXED_VALUE_INDICATOR_WIDTH)

    const marginLeft = this.props.minMarginLeft ? Math.max(this.props.minMarginLeft, categoryLeft) : categoryLeft

    return (
      <div className="StepSlider">
        {categories ? (
          <div
            className="category"
            style={{
              width: width - categoryLeft,
              marginLeft: marginLeft,
            }}
          >
            <div className="name" style={{ width: CATEGORY_WIDTH }}>
              {categoryName}
            </div>
          </div>
        ) : null}

        <div
          className="tooltip"
          style={{
            width:
              width - tooltipLeft < tooltipPostfixWidthHelper && tooltipPostfix
                ? tooltipPostfixWidthHelper + KNOB_WIDTH
                : width - tooltipLeft,
            marginLeft: tooltipLeft - (tooltipPostfix ? tooltipPostfixWidthHelper / 2 - KNOB_WIDTH : 0),
          }}
        >
          <div
            // @ts-ignore
            ref={(ref) => (this.TooltipValue = ref)}
            className="value"
            style={{
              width: tooltipPostfix ? tooltipPostfixWidthHelper : KNOB_WIDTH,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <span>
              {formatValue(value)}
              {tooltipPostfix ? (
                tooltipPostfixNormal ? (
                  <span style={{ marginLeft: '2px' }}>{tooltipPostfix}</span>
                ) : (
                  <sup
                    style={{
                      fontSize: '0.6em',
                      marginLeft: '2px',
                    }}
                  >
                    {tooltipPostfix}
                  </sup>
                )
              ) : null}
            </span>
          </div>

          {valueBottom ? <div className="valueBottom">{valueBottom}</div> : null}
        </div>

        <Slider
          className="slider-control"
          disabled={disabled}
          step={step}
          min={min}
          max={max}
          value={customScale ? customReverse(value) : value}
          style={{ width: sliderWidth }}
          onChange={
            customScale
              ? (event: Object, value: number) => {
                  value = customTransform(value)
                  onChange(Object, value)
                }
              : onChange
          }
        />

        {showMinMax ? (
          <div
            className="minMax"
            style={{
              width: sliderWidth,
              clear: 'both',
              fontSize: '0.6em',
              marginBottom: MINOR_ADJUST,
            }}
          >
            <span className="color-gray" style={{ float: 'left' }}>
              {minText ? minText : customScale ? formatValue(customMin) : formatValue(min)}
            </span>
            <span className="color-gray" style={{ float: 'right' }}>
              {customScale ? formatValue(customMax) : formatValue(max)}
            </span>
          </div>
        ) : null}

        {fixedValueLeft ? (
          <div
            className="tooltip"
            style={{
              width: fixedValueWidth,
              marginLeft: fixedValueMarginLeft,
            }}
          >
            <div
              // @ts-ignore
              ref={(ref) => (this.TooltipValue = ref)}
              className="value"
              style={{ width: FIXED_VALUE_INDICATOR_WIDTH }}
            >
              <span className="color-gray">&#x25B2;</span>
            </div>
          </div>
        ) : null}

        {fixedValueLeft ? (
          <div
            className="value"
            style={{
              width: fixedLabelWidth,
              marginLeft: fixedLabelMarginLeft,
            }}
          >
            <div className="name color-gray sm" style={{ width: FIXED_VALUE_LABEL_WIDTH }}>
              Ihre Risikostufe
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default StepSlider
