import { Action } from './types'
import { User } from 'reducers/user'

export function fetchAdminData(data: User): Action {
  return {
    type: 'FETCH_ADMIN_DATA',
    payload: data,
  }
}

export function fetchInitialAdmin(adminData: User, clients: User[]): Action {
  return {
    type: 'FETCH_INITIAL_ADMIN',
    payload: { adminData, clients },
  }
}

export function fetchCustomers(customers: User[]): Action {
  return {
    type: 'FETCH_CUSTOMERS',
    payload: customers,
  }
}

export function fetchTasks(tasks: Array<Task>): Action {
  return {
    type: 'FETCH_TASKS',
    payload: tasks,
  }
}

export function filterCompany(company?: string): Action {
  return {
    type: 'FILTER_COMPANY',
    payload: company,
  }
}

export function filterInstitute(institute?: string): Action {
  return {
    type: 'FILTER_INSTITUTE',
    payload: institute,
  }
}

export function filterStatus(status?: number): Action {
  return {
    type: 'FILTER_STATUS',
    payload: status,
  }
}

export function filterType(type?: number): Action {
  return {
    type: 'FILTER_TYPE',
    payload: type,
  }
}

export function filterFulltext(fulltext?: string): Action {
  return {
    type: 'FILTER_FULLTEXT',
    payload: fulltext,
  }
}

export function deleteClient(id: number): Action {
  return {
    type: 'DELETE_CLIENT',
    payload: id,
  }
}

export function fetchMessages(messages: User[]): Action {
  return {
    type: 'FETCH_MESSAGES',
    payload: messages,
  }
}

export function fetchMessage(messageData: {}): Action {
  return {
    type: 'FETCH_MESSAGE',
    payload: messageData,
  }
}

export function deleteMessage(id: number): Action {
  return {
    type: 'DELETE_MESSAGE',
    payload: id,
  }
}
