'use strict'

import classnames from 'classnames'

import config from '../../../config/config'
import { ProductCategory } from '../../../reducers/main'

import ContactBox from '../ContactBox'
import ProductInfo from '../ProductInfo'
import { HelpBox, PlanParametersSidebarBox } from '../SidebarBox'
import Logo from '../Logo/Logo'

type Props = {
  className?: string
  currencySign?: string
  currentValue?: number
  targetCategory?: number | Object
  productCategories?: ProductCategory[]

  hideContents?: boolean
  showDetails?: boolean
  reverseOrder?: boolean
  deposit?: number
  targetAmount?: number
  targetRate?: number
  targetYear?: number
  savingsRate?: number

  depositVisible?: boolean
  targetAmountVisible?: boolean

  profileMode?: boolean
  fullname?: string
  isMobile?: boolean
  resources?: Object

  planParametersVisible: boolean
  planParametersYearEditable?: boolean
  planParametersSavingsRateEditable?: boolean
  planParameterstargetCategoryEditable?: boolean
}

function ProductInfoSidebar({
  className,
  currencySign,
  currentValue,
  targetCategory,
  productCategories,
  reverseOrder,
  showDetails,
  hideContents,
  deposit,
  targetAmount,
  targetRate,
  targetYear,
  savingsRate,
  profileMode,
  fullname,
  isMobile,
  targetAmountVisible,
  depositVisible,
  resources,
  planParametersVisible,
  planParametersSavingsRateEditable,
  planParametersYearEditable,
  planParameterstargetCategoryEditable,
}: Props) {
  const classes = classnames([
    'ProductInfoSidebar',
    { 'with-details': showDetails },
    { 'hide-contents': hideContents },
    className,
  ])

  return (
    <div className={classes}>
      <div className="logoContainer">
        <Logo link={config.logoLink} />
      </div>

      {resources && <ContactBox resources={resources.contactBox} />}

      {!hideContents && (
        <ProductInfo
          currencySign={currencySign}
          currentValue={currentValue}
          targetCategory={targetCategory}
          productCategories={productCategories}
          reverseOrder={reverseOrder}
          showDetails={showDetails}
          hideContents={hideContents}
          deposit={deposit ? deposit.toString() : null}
          targetAmount={targetAmount}
          targetRate={targetRate}
          targetYear={targetYear}
          savingsRate={savingsRate ? savingsRate.toString() : null}
          profileMode={profileMode}
          fullname={fullname}
          targetAmountVisible={targetAmountVisible}
          depositVisible={depositVisible}
          resources={resources}
          isMobile={isMobile}
        />
      )}

      <HelpBox />

      <PlanParametersSidebarBox
        resources={resources}
        visible={planParametersVisible}
        depositVisible={depositVisible}
        targetAmountVisible={targetAmountVisible}
        yearEditable={planParametersYearEditable}
        savingsRateEditable={planParametersSavingsRateEditable}
        targetCategoryEditable={planParameterstargetCategoryEditable}
      />
    </div>
  )
}

export default ProductInfoSidebar
