import config from 'config/config'
import ProductMinimumValueService from 'services/ProductMinimumValueService'
import PlanParametersService from 'services/PlanParametersService'
import PORTFOLIO_CATEGORIES from '../../../common/portfolioCategories'

import AbstractSidebarBox from './AbstractSidebarBox'
import InputNumber from '../InputNumber'
import { Select, MenuItem } from '../Select'
import StepSlider from '../StepSlider'

type Props = {
  resources?: Object
  visible: boolean
  depositVisible?: boolean
  targetAmountVisible?: boolean
  savingsRateEditable?: boolean
  yearEditable?: boolean
  targetCategoryEditable?: boolean
}

type State = {
  isLoading: boolean
  targetCategory: number
  targetAmount: number
  targetYear: number
  deposit: number
  savingsRate: number
  portfolio: number
  productDepositMinValue: number
  productSavingsRatetMinValue: number
  targetCategoryEditable: boolean
  yearEditable: boolean
  savingsRateEditable: boolean
  targetAmountVisible: boolean
  depositVisible: boolean
}

class PlanParametersSidebarBox extends AbstractSidebarBox {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)
    const parametersService = PlanParametersService.getInstance()

    this.handleDataLoadingUpdate = this.handleDataLoadingUpdate.bind(this)
    this.handleTargetCategoryUpdate = this.handleTargetCategoryUpdate.bind(this)
    this.handleTargetAmountUpdate = this.handleTargetAmountUpdate.bind(this)
    this.handleTargetYearUpdate = this.handleTargetYearUpdate.bind(this)
    this.handleDepositUpdate = this.handleDepositUpdate.bind(this)
    this.handleSavingsRateUpdate = this.handleSavingsRateUpdate.bind(this)
    this.handlePortfolioUpdate = this.handlePortfolioUpdate.bind(this)

    parametersService.registerDataLoadingListener(this.handleDataLoadingUpdate)
    parametersService.registerTargetCategoryListener(this.handleTargetCategoryUpdate)
    parametersService.registerTargetAmountListener(this.handleTargetAmountUpdate)
    parametersService.registerTargetYearListener(this.handleTargetYearUpdate)
    parametersService.registerDepositListener(this.handleDepositUpdate)
    parametersService.registerSavingsRateListener(this.handleSavingsRateUpdate)
    parametersService.registerPortfolioListener(this.handlePortfolioUpdate)

    this.state = {
      isLoading: parametersService.isDataLoading(),
      targetCategory: parametersService.getTargetCategory(),
      targetAmount: parametersService.getTargetAmount(),
      targetYear: parametersService.getTargetYear(),
      deposit: parametersService.getDeposit(),
      savingsRate: parametersService.getSavingsRate(),
      portfolio: parametersService.getPortfolio(),
      depositVisible: props.depositVisible === undefined || props.depositVisible === null ? true : props.depositVisible,
      targetAmountVisible:
        props.targetAmountVisible === undefined || props.targetAmountVisible === null
          ? true
          : props.targetAmountVisible,
      savingsRateEditable:
        props.savingsRateEditable === undefined || props.savingsRateEditable === null
          ? true
          : props.savingsRateEditable,
      yearEditable: props.yearEditable === undefined || props.yearEditable === null ? true : props.yearEditable,
      targetCategoryEditable:
        props.targetCategoryEditable === undefined || props.targetCategoryEditable === null
          ? true
          : props.targetCategoryEditable,
    }
  }

  getProductDepositMinValue() {
    return ProductMinimumValueService.getInstance().getProductDepositMinValue(
      PlanParametersService.getInstance().getTargetCategory(),
    )
  }

  getProductSavingsRateMinValue() {
    return ProductMinimumValueService.getInstance().getProductSavingsRateMinValue(
      PlanParametersService.getInstance().getTargetCategory(),
    )
  }

  handleDataLoadingUpdate(isLoading) {
    this.state.isLoading = isLoading // TODO: we should fix this code by making sure the box has its proper state, probably through composition over inheritance
    this.setState(
      {
        ...this.state,
        isLoading,
        depositVisible:
          this.props.depositVisible === undefined || this.props.depositVisible === null
            ? true
            : this.props.depositVisible,
      },
      () => {
        this.renderContent()
      },
    )
  }

  handleTargetCategoryUpdate(targetCategory) {
    this.state.targetCategory = targetCategory
    this.setState({ ...this.state, targetCategory })
  }

  handleTargetAmountUpdate(targetAmount) {
    this.state.targetAmount = targetAmount
    this.setState({ ...this.state, targetAmount })
  }

  handleTargetYearUpdate(targetYear) {
    this.state.targetYear = targetYear
    this.setState({ ...this.state, targetYear })
  }

  handleDepositUpdate(deposit) {
    this.state.deposit = deposit
    this.setState({ ...this.state, deposit })
  }

  handleSavingsRateUpdate(savingsRate) {
    this.state.savingsRate = savingsRate
    this.setState({ ...this.state, savingsRate })
  }

  handlePortfolioUpdate(portfolio) {
    this.state.portfolio = portfolio
    this.setState({ ...this.state, portfolio })
  }

  updateTargetCategory(targetCategory) {
    PlanParametersService.getInstance().setTargetCategory(targetCategory)
  }

  updateTargetAmount(targetAmount) {
    PlanParametersService.getInstance().setTargetAmount(targetAmount)
  }

  updateTargetYear(targetYear) {
    PlanParametersService.getInstance().setTargetYear(targetYear)
  }

  updateDeposit(deposit) {
    PlanParametersService.getInstance().setDeposit(deposit)
  }

  updateSavingsRate(savingsRate) {
    PlanParametersService.getInstance().setSavingsRate(savingsRate)
  }

  updatePortfolio(portfolio) {
    PlanParametersService.getInstance().setPortfolio(portfolio)
  }

  isVisible() {
    return this.props.visible && !this.state.isLoading
  }

  isCategoryVisible(id: string): boolean {
    return !config.hiddenCategories || config.hiddenCategories.indexOf(id) < 0
  }

  getTargetCategoryText() {
    const { goalPension, goalSavings, goalInvestment, goalKids } = this.props.resources.sidebar
    const { targetCategory } = this.state

    switch (targetCategory) {
      case 1:
        return goalPension
      case 2:
        return goalInvestment
      case 3:
        return goalKids
      case 4:
        return goalSavings
      default:
        return ''
    }
  }

  updateDepositInputValue = (value: number) => {
    this.updateDeposit(value)
  }

  updateSavingsRateInputValue = (value: number) => {
    this.updateSavingsRate(value)
  }

  renderContent() {
    const {
      goal,
      goalPension,
      goalSavings,
      goalInvestment,
      targetValue,
      inYear,
      singlePayment,
      regularPayment,
      portfolio,
    } = this.props.resources.sidebar

    const currencySign = '€'

    const {
      targetCategory,
      targetAmount,
      targetYear,
      deposit,
      savingsRate,
      depositVisible,
      targetAmountVisible,
      savingsRateEditable,
      yearEditable,
      targetCategoryEditable,
    } = this.state

    const portfolioValue = this.state.portfolio

    return (
      <div className="planParametersSidebarBox">
        <div className="control">
          <div className="title">{goal}</div>

          {targetCategoryEditable && targetCategory !== 3 ? (
            <Select
              id="targetCategory"
              value={this.state.targetCategory}
              onChange={(e, k, value) => this.updateTargetCategory(value)}
              style={{ width: '100%', textAlign: 'center', marginTop: 0 }}
            >
              {this.isCategoryVisible('pension') && <MenuItem value={1}>{goalPension}</MenuItem>}
              {this.isCategoryVisible('savings') && <MenuItem value={4}>{goalSavings}</MenuItem>}
              {this.isCategoryVisible('investment') && <MenuItem value={2}>{goalInvestment}</MenuItem>}
            </Select>
          ) : (
            <div className={'readonly-text'}>{this.getTargetCategoryText()}</div>
          )}
        </div>

        {targetAmountVisible && (
          <div className="control">
            <div className="title">{targetValue}</div>
            <InputNumber
              id="targetAmount"
              className="small"
              dynamicIncrement
              prefix={currencySign}
              value={targetAmount ? targetAmount : 0}
              onTextChange={this.updateTargetAmount}
              onIncrement={this.updateTargetAmount}
              onDecrement={this.updateTargetAmount}
            />
          </div>
        )}

        <div className="control">
          <div className="title">{inYear}</div>
          <InputNumber
            id="targetYear"
            className="small"
            forceIncrementBy={1}
            disabled={!yearEditable}
            disableFormatting
            disableKeyboardInput
            value={targetYear}
            min={new Date().getFullYear() + 3}
            onTextChange={this.updateTargetYear}
            onIncrement={this.updateTargetYear}
            onDecrement={this.updateTargetYear}
          />
        </div>

        {depositVisible && (
          <div className="control">
            <div className="title">{singlePayment}</div>
            <InputNumber
              id="deposit"
              className="small"
              dynamicIncrement
              prefix={currencySign}
              value={deposit}
              onTextChange={this.updateDepositInputValue}
              onIncrement={this.updateDepositInputValue}
              onDecrement={this.updateDepositInputValue}
              // onBlur={this.updateDepositInputValue}
            />
          </div>
        )}

        <div className="control">
          <div className="title">{regularPayment}</div>
          <InputNumber
            id="savingsRate"
            className="small"
            incrementBy={25}
            prefix={currencySign}
            value={savingsRate}
            disabled={!savingsRateEditable}
            onTextChange={this.updateSavingsRateInputValue}
            onIncrement={this.updateSavingsRateInputValue}
            onDecrement={this.updateSavingsRateInputValue}
            // onBlur={this.updateSavingsRateInputValue}
          />
        </div>

        <div className="control">
          <div className="title">{portfolio}</div>
          <StepSlider
            firstTooltipPositionCentered
            min={2}
            max={10}
            step={1}
            value={portfolioValue}
            minMarginLeft={40}
            categories={PORTFOLIO_CATEGORIES}
            onChange={(event: Object, value: any) => this.updatePortfolio(value)}
          />
        </div>
      </div>
    )
  }

  getIconClassName() {
    return 'pen'
  }
}

export default PlanParametersSidebarBox
