// SIGNUP
import defaultSignupResources from './signup-default.json'
import bavSignupResources from './signup-bav.json'
import completionSignupResources from './completion-signup.json'
import userSignupResources from './user-signup.json'

// INSIDE AREA
import defaultInsideResources from './inside-default.json'
import insideBavResources from './inside-bav.json'

// CRM
import crmResources from './crm.json'

export {
  defaultSignupResources,
  bavSignupResources,
  completionSignupResources,
  userSignupResources,
  defaultInsideResources,
  insideBavResources,
  crmResources,
}
