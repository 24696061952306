import { Component } from 'react'

class AbstractSidebarBox extends Component {
  constructor(props) {
    super(props)
  }

  renderContent() {
    throw new Error('Method renderContent needs to be implemented')
  }

  getIconClassName() {
    throw new Error('Method getIconClassName needs to be implemented')
  }

  isVisible() {
    return true
  }

  render() {
    if (this.isVisible()) {
      return (
        <div key="sidebarBoxContainer" className={'sidebarBoxContainer'}>
          <div className={'sidebarBoxContent'}>
            <div className={'icon-border'} />
            <div className={'icon ' + this.getIconClassName()} />
            <div className={'sidebarBox'}>{this.renderContent()}</div>
          </div>
        </div>
      )
    } else {
      return <div />
    }
  }
}

export default AbstractSidebarBox
