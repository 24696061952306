type Props = {
  className?: string
  children?: Object
  id?: string
  style?: Object
}

const Tooltip = ({ className, children, id, style }: Props) => (
  <div className={className ? `Tooltip ${className}` : 'Tooltip'} id={id} style={style}>
    {children}
  </div>
)

export default Tooltip
