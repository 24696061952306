type Props = {
  resources: Object
  adminModus?: boolean
  withContactLinks?: boolean
}

function Footer({ resources, adminModus, withContactLinks }: Props) {
  function getFooter(resources) {
    return (
      <div className={`footer-links xs ${adminModus ? 'admin' : ''}`}>
        <a href={resources.footer.dataProtectionLink} target="_blank" rel="noopener" className="privacy">
          Datenschutz
        </a>
        <a href={resources.footer.imprintLink} target="_blank" rel="noopener" className="imprint">
          Impressum
        </a>
        <a href={'mailto:' + resources.footer.email} className="email">
          {resources.footer.email}
        </a>
        <a href={'tel:' + resources.footer.phoneLink} className="phone">
          {resources.footer.phone}
        </a>
      </div>
    )
  }

  function getNormalFooter() {
    return (
      <div className="footer-links xs">
        <a href="https://www.finabro.at/datenschutz" target="_blank" rel="noopener" className="privacy">
          Datenschutz
        </a>
        <a href="https://www.finabro.at/impressum" target="_blank" rel="noopener" className="imprint">
          Impressum
        </a>
      </div>
    )
  }

  function getFooterWithContactLinks(resources) {
    return (
      <>
        <div className="Footer__column">
          <div className="column-header">{resources.footer.title}</div>
          <div className="column-content">{resources.footer.subtitle}</div>
        </div>

        <div className="Footer__column">
          <div className="column-header">{resources.footer.column1.title}</div>
          <div className="column-content">
            <div>
              <a href={'tel:' + resources.footer.phoneLink}>{resources.footer.phone}</a>
            </div>
            <div>
              <a href={'mailto:' + resources.footer.email}>{resources.footer.email}</a>
            </div>

            {resources.footer.column1.link1 && (
              <div>
                <a href={resources.footer.column1.link1.link} target="_blank" rel="noopener">
                  {resources.footer.column1.link1.description}
                </a>
              </div>
            )}
          </div>
        </div>

        <div className="Footer__column">
          <div className="column-header">{resources.footer.column2.title}</div>
          <div className="column-content">
            {resources.footer.column2.link1 && (
              <div>
                <a href={resources.footer.column2.link1.link} target="_blank" rel="noopener" className="Footer_link">
                  {resources.footer.column2.link1.description}
                </a>
              </div>
            )}

            {resources.footer.column2.link2 && (
              <div>
                <a href={resources.footer.column2.link2.link} target="_blank" rel="noopener" className="Footer_link">
                  {resources.footer.column2.link2.description}
                </a>
              </div>
            )}

            {resources.footer.column2.link3 && (
              <div>
                <a href={resources.footer.column2.link3.link} target="_blank" rel="noopener" className="Footer_link">
                  {resources.footer.column2.link3.description}
                </a>
              </div>
            )}
          </div>
        </div>

        <div className="Footer__column">
          <div className="column-header">{resources.footer.column3.title}</div>
          <div className="column-content">
            {resources.footer.column3.link2 && (
              <div>
                <a href={resources.footer.column3.link2.link} target="_blank" rel="noopener" className="Footer_link">
                  {resources.footer.column3.link2.description}
                </a>
              </div>
            )}

            {resources.footer.column3.link3 && (
              <div>
                <a href={resources.footer.column3.link3.link} target="_blank" rel="noopener" className="Footer_link">
                  {resources.footer.column3.link3.description}
                </a>
              </div>
            )}

            {resources.footer.column3.link4 && (
              <div>
                <a href={resources.footer.column3.link4.link} target="_blank" rel="noopener" className="Footer_link">
                  {resources.footer.column3.link4.description}
                </a>
              </div>
            )}

            {resources.footer.column3.link5 && (
              <div>
                <a href={resources.footer.column3.link5.link} target="_blank" rel="noopener" className="Footer_link">
                  {resources.footer.column3.link5.description}
                </a>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }

  return (
    <div id="signup-footer" className="Signup__footer">
      {withContactLinks ? getFooterWithContactLinks(resources) : resources ? getFooter(resources) : getNormalFooter()}
    </div>
  )
}

export default Footer
