import classnames from 'classnames'

type Props = {
  image?: string
  inline?: boolean
  style?: Object
  superscript?: boolean
  textIcon?: string
  onClick?: (event: Object) => void
}

function Symbol({ image, inline, style, superscript, textIcon, onClick }: Props) {
  const classes = classnames(['Symbol', { inline: inline }, { superscript: superscript }, { textIcon: textIcon }])

  return (
    <span className={classes} style={style} onClick={onClick}>
      {image ? <img src={image} /> : textIcon}
    </span>
  )
}

export default Symbol
