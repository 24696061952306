import { useState } from 'react'
import Modal from 'components/UI/Modal'

type Props = {
  resources: Object
  imageURL: string
}

function ContactBox({ resources }: Props) {
  const [showModal, setShowModal] = useState(false)

  // const randomNumber = Math.floor(Math.random() * 3 + 1)

  return (
    <>
      <div className="ContactBox" onClick={() => setShowModal(true)}>
        <div className="ContactBox__text">
          <h2 className="ContactBox__title">{resources.title}</h2>
          <span className="ContactBox__subtitle">{resources.subtitle}</span>
        </div>
        <div className="ContactBox__imageWrapper">
          <img className="ContactBox__image" src="/assets/images/contactbox/contact-finabro.jpg" />
        </div>
      </div>

      <Modal className="contactBoxModal" isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <p className="contactBoxModal__text" dangerouslySetInnerHTML={{ __html: resources.modal.text }} />
      </Modal>
    </>
  )
}

export default ContactBox
