export default [
  {
    name: 'Niedriges Risiko',
    values: [1, 2, 3],
  },
  {
    name: 'Mittleres Risiko',
    values: [4, 5, 6, 7],
  },
  {
    name: 'Hohes Risiko',
    values: [8, 9, 10],
  },
]
