function WebIdFail() {
  return (
    <div>
      <h1 className="Step__title--error">Identifikationsfehler</h1>

      <div className="currentStepContent">
        <div className="content-center-lefttext">
          <p>Leider konnte ihre Identifikation nicht erfolgreich abgeschlossen werden.</p>
          <p>
            Bitte kontaktieren Sie uns unter <a href="mailto: service@finabro.at">service@finabro.at</a> oder{' '}
            <a href="tel:0043720884146">+43 720 88 41 46</a>,<br />
            um die Identifikation erneut durchzuführen.
          </p>
        </div>
      </div>
    </div>
  )
}

export default WebIdFail
