import { combineReducers } from 'redux'

import adminReducer from './admin'
import mainReducer from './main'
import signupReducer from './signup'
import userReducer from './user'
import appReducer from './app'

export default combineReducers({
  admin: adminReducer,
  main: mainReducer,
  app: appReducer,
  signup: signupReducer,
  user: userReducer,
})
