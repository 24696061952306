import { defaultSignupResources } from 'resources'

function AccountActivated() {
  const resources = defaultSignupResources

  return (
    <div className="AccountActivated container">
      <h1>{resources.AccountActivated.title}</h1>

      <div className="currentStepContent">
        <div className="content-left">
          <p>{resources.AccountActivated.text1}</p>
        </div>
      </div>
    </div>
  )
}

export default AccountActivated
