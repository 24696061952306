import isEmail from 'validator/lib/isEmail'
import { COUNTRIES_ISO } from 'core/countries'

export const insert = (arr, index, ...newItems) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted items
  ...newItems,
  // part of the array after the specified index
  ...arr.slice(index),
]

export function isValidEmail(email) {
  return typeof email === 'string' && isEmail(email)
}

function isNumeric(value) {
  if (typeof value === 'number') return true

  if (typeof value === 'string') {
    return !isNaN(value) && !isNaN(parseFloat(value)) && !value.startsWith('+')
  }

  return false
}

export function transformStringValues(data) {
  return Object.keys(data).reduce((acc, key) => {
    acc[key] = data[key]

    // Number
    if (isNumeric(acc[key])) {
      // convert string to number
      acc[key] = Number(acc[key])
    } else {
      // Boolean, Array, Object
      try {
        acc[key] = JSON.parse(acc[key])
      } catch (error) {
        acc[key] = data[key]
      }
    }

    return acc
  }, {})
}

export function getMaxPortfolio(risikostufe) {
  if (risikostufe <= 4) return 4
  if (risikostufe <= 9) return 9
  if (risikostufe === 10) return 10
  return null
}

export function getIsoCountryCode(countryName) {
  return COUNTRIES_ISO[countryName]
}

// File saving
// TODO: simplify these
export function saveFile(file, name = '') {
  const fileURL = URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = fileURL
  link.setAttribute('download', name)
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(fileURL)
  document.body.removeChild(link)
}

// // not used
// export function saveFileFromURL(url, fileName = '') {
//   const link = document.createElement('a')
//   link.href = url
//   link.setAttribute('download', fileName)
//   link.style.display = 'none'
//   document.body.appendChild(link)
//   link.click()
//   document.body.removeChild(link)
// }

export const saveCSV = (data, fileName) => {
  const link = document.createElement('a')
  link.setAttribute('href', `data:attachment/csv;charset=utf-8,${encodeURI(data)}`)
  link.setAttribute('download', `${fileName}.csv`)
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function downloadCSV(filename, csv) {
  const link = document.createElement('a')
  link.setAttribute('href', 'data:application/octet-stream,' + encodeURIComponent(csv))
  link.setAttribute('download', filename)
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
