import { Component, Children, cloneElement } from 'react'
import { Select as MaterialUiSelect, MenuItem, FormControl, FormHelperText, InputLabel } from '@material-ui/core'

import Symbol from '../Symbol'
import Tooltip from '../Tooltip'

type Props = {
  id: string
  children?: Object
  className?: string
  hintBottom?: string | Object
  tooltip?: Object | string
  tooltipStyle?: Object
  label?: string
  labelFixed?: string
  prefix?: string
  // NOTE: add further sizes here if required
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'sl'
  style?: Object
}

class Select extends Component {
  props: Props

  render() {
    const {
      id,
      children,
      className,
      hintBottom,
      tooltip,
      tooltipStyle,
      labelFixed,
      prefix,
      size,
      style,
      onChange,
      floatingLabelText,
      helperText,
      label,
      value,
      error,
      noMatchErrorText,
      searchPlaceholder,
      ...other
    } = this.props

    // This filter will return only not null values
    const childrenWithoutNull = Children.toArray(children)
    const childrenWithIDs = childrenWithoutNull.map((child, index) => {
      return cloneElement(child, { id: `${id}-${index}`, key: `${id}-${index}` })
    })

    const labelToUse = label || floatingLabelText
    const defaultStyles = { marginTop: 16 }
    const sizeStyles =
      size === 'xxs'
        ? { width: 55 }
        : size === 'xs'
        ? { width: 100 }
        : size === 'sm'
        ? { width: 150 }
        : size === 'md'
        ? { width: 200 }
        : size === 'lg'
        ? { width: 220 }
        : size === 'xl'
        ? { width: 280 }
        : size === 'xxl'
        ? { width: 350 }
        : size === 'sl'
        ? { width: 400 }
        : null

    const selectStyles = { ...defaultStyles, ...sizeStyles, ...style }
    const selectClassName = `MuiSelect ${prefix ? 'selectAfterPrefix' : 'selectWithoutPrefix'}`

    const selectComponent = (
      <MaterialUiSelect
        style={selectStyles}
        classes={{ select: selectClassName }}
        value={value || ''}
        error={error}
        onChange={(e) => {
          if (onChange) onChange(e, e.target.value, e.target.value)
        }}
        {...other}
      >
        {childrenWithIDs}
      </MaterialUiSelect>
    )

    const component = labelToUse ? (
      <FormControl style={{ marginTop: 8, marginBottom: 8 }}>
        <InputLabel error={error}>{labelToUse}</InputLabel>
        {selectComponent}
        {helperText && helperText.length > 0 ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    ) : (
      selectComponent
    )

    return (
      <div
        id={id}
        className={`Select column-left text-align-left ${className ? className : ''}`}
        style={labelToUse ? null : { marginTop: 8, marginBottom: 16 }}
      >
        {labelFixed && (
          <div className="labelFixed sm">
            {labelFixed}
            {tooltip && (
              <div className="InfoTooltip2">
                <Symbol superscript textIcon="i" />
                <Tooltip style={tooltipStyle}>{tooltip}</Tooltip>
              </div>
            )}
          </div>
        )}

        {prefix && <div className="prefix sm">{prefix}</div>}
        {component}
        {hintBottom && <div className="hintBottom xs">{hintBottom}</div>}
      </div>
    )
  }
}

export { MenuItem, Select }
