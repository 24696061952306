class PlanParametersService {
  static INSTANCE = new PlanParametersService()

  static getInstance() {
    return PlanParametersService.INSTANCE
  }

  constructor() {
    this.dataLoading = true
    this.targetCategory = null
    this.targetAmount = 0
    this.targetYear = 2020
    this.deposit = 0
    this.savingsRate = 0
    this.portfolio = 3
    this.dataLoadingListeners = []
    this.targetCategoryListeners = []
    this.targetAmountListeners = []
    this.targetYearListeners = []
    this.depositListeners = []
    this.savingsRateListeners = []
    this.portfolioListeners = []
  }

  setDataLoading(dataLoading) {
    console.log('PlanParametersService: Setting data loading to', dataLoading)
    this.dataLoading = dataLoading
    this.dataLoadingListeners.forEach((l) => l(dataLoading))
  }

  setTargetCategory(targetCategory) {
    console.log('PlanParametersService: Setting target category to', targetCategory)
    this.targetCategory = targetCategory
    this.targetCategoryListeners.forEach((l) => l(targetCategory))
  }

  setTargetAmount(targetAmount) {
    console.log('PlanParametersService: Setting target amount to', targetAmount)
    this.targetAmount = targetAmount
    this.targetAmountListeners.forEach((l) => l(targetAmount))
  }

  setTargetYear(targetYear) {
    console.log('PlanParametersService: Setting target year to', targetYear)
    this.targetYear = targetYear
    this.targetYearListeners.forEach((l) => l(targetYear))
  }

  setDeposit(deposit) {
    console.log('PlanParametersService: Setting deposit to', deposit)
    this.deposit = deposit
    this.depositListeners.forEach((l) => l(deposit))
  }

  setSavingsRate(savingsRate) {
    console.log('PlanParametersService: Setting savings rate to', savingsRate)
    this.savingsRate = savingsRate
    this.savingsRateListeners.forEach((l) => l(savingsRate))
  }

  setPortfolio(portfolio) {
    console.log('PlanParametersService: Setting portfolio to', portfolio)
    this.portfolio = portfolio
    this.portfolioListeners.forEach((l) => l(portfolio))
  }

  isDataLoading() {
    return this.dataLoading
  }

  getTargetCategory() {
    return this.targetCategory
  }

  getTargetAmount() {
    return this.targetAmount
  }

  getTargetYear() {
    return this.targetYear
  }

  getDeposit() {
    return this.deposit
  }

  getSavingsRate() {
    return this.savingsRate
  }

  getPortfolio() {
    return this.portfolio
  }

  registerDataLoadingListener(listener) {
    this.dataLoadingListeners.push(listener)
  }

  registerTargetCategoryListener(listener) {
    this.targetCategoryListeners.push(listener)
  }

  registerTargetAmountListener(listener) {
    this.targetAmountListeners.push(listener)
  }

  registerTargetYearListener(listener) {
    this.targetYearListeners.push(listener)
  }

  registerDepositListener(listener) {
    this.depositListeners.push(listener)
  }

  registerSavingsRateListener(listener) {
    this.savingsRateListeners.push(listener)
  }

  registerPortfolioListener(listener) {
    this.portfolioListeners.push(listener)
  }
}

export default PlanParametersService
