// Babel
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { render } from 'react-dom'
import { Provider } from 'react-redux'
import Modal from 'react-modal'

import 'utils/polyfills'
import store from './store'
import AppRouter from './AppRouter'

// fix mobile #app minHeight, see theme.ts
function appHeight() {
  const doc = document.documentElement
  doc.style.setProperty('--vh', window.innerHeight * 0.01 + 'px')
}

window.addEventListener('resize', appHeight)
appHeight()

Modal.setAppElement('#app')

render(
  <Provider store={store}>
    <AppRouter />
  </Provider>,
  document.querySelector('#app'),
)
