import { Action } from 'actions/types'

type State = {
  pageTitle: string
}

const initialState: State = {
  pageTitle: null,
}

export default function appReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'SET_PAGE_TITLE':
      return {
        ...state,
        pageTitle: action.payload.title,
      }

    default:
      return state
  }
}
