import { Action } from 'actions/types'
import { Risiko } from 'reducers/user'

export type SignupData = {
  dbid: number
  uuid: string

  automaticallyStartSignup?: boolean
  prevLocation?: string
  kundennr?: string
  produktId?: number
  webid_files?: Object

  user_vorname: string
  user_nachname: string
  user_anrede: string
  user_titel: string
  user_post_titel: string
  user_geburtsDatum: string
  user_geburtsLand: string
  user_geburtsOrt: string
  user_nationalitaet: string
  user_strasse: string
  user_plz: number | string
  user_ort: string
  user_land: string
  user_telefon: string
  user_email: string
  user_email_privat: string
  user_unternehmen: string
  user_variant: string
  user_passwort: string
  // save last completed signup step
  signup_progress: number
  // status === 1 ('Signup abgebrochen')
  // status === 2 ('Dok an Kunde schicken')
  // status === 3 ('Dok bei Kunde')
  // status === 4 ('Dok bei Partner')
  // status === 5 ('Konto eröffnet')
  user_status: number
  // 0 or undefined for default SignUp
  // 1 for firmen-signup
  signup_type?: number

  user_nopep: boolean
  user_zustimmungaufzeichnung: boolean
  user_sepa: boolean
  user_steuerid: string

  user_ausbildung: string
  user_beruf: string
  user_branche: string
  user_steuerLand: string
  user_USsteuerpflichtig: boolean
  user_nebenwohnsitz: boolean
  user_drittLand?: string

  user_konto_iban: string
  user_konto_bic: string
  user_konto_institut: string

  user_ausweis_dokument: string
  user_ausweis_nummer: number | string
  user_ausweis_ausstellungsBehoerde: string
  user_ausweis_ausstellungsLand: string
  user_ausweis_ausstellungsDatum: string
  user_ausweis_ablaufDatum: string

  user_serviceExperience_onlineBank: boolean
  user_serviceExperience_investmentConsulting: boolean
  user_serviceExperience_assetsConsulting: boolean

  user_investmentExperience_sparbuch: ExperienceDetails
  user_investmentExperience_investmentFonds: ExperienceDetails
  user_investmentExperience_anleihen: ExperienceDetails
  user_investmentExperience_aktien: ExperienceDetails

  user_familyStatus: string
  user_income: number
  user_incomeTyp: string
  user_fixedAssets: string
  user_liquidAssets: string
  user_assetsOrigin_income: boolean
  user_assetsOrigin_inheritance: boolean
  user_assetsOrigin_companysale: boolean
  user_assetsOrigin_sonstiges: string

  user_referrer: string

  product_partner: string
  product_targetCategory: number
  product_age?: number
  product_horizont?: number
  product_targetAmount?: number
  product_targetRate?: number
  product_targetYear?: number
  product_portfolio: Risiko
  product_risiko: Risiko
  product_deposit: number
  product_savingsRate: number
  product_savingsPlanTyp: string
  product_wachstumsRate?: number
  product_prodTyp: number
  product_eigeneRechnung?: boolean
  product_vertragsOrt?: string
  product_umwandlung?: boolean

  beneficiary_anrede: string
  beneficiary_vorname: string
  beneficiary_nachname: string
  beneficiary_geburtsDatum: string

  webid_valid?: boolean
  webid_actionId?: string
  webid_url?: string

  isCompletionSignup?: boolean
  isUserSignup?: boolean
  isEmailWhitelisted?: boolean
}

export type ExperienceDetails = {
  experience: boolean
  frequency?: string
  valueRange?: string
}

type State = SignupData

const initialState: State = null

export default function signupReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'SAVE_SIGNUP_DATA':
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
