import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { color, breakpoint } from '@finabro-ui/components'

import AuthService from 'services/api/auth'

const Error = styled.div`
  color: ${color.danger};
  width: 100%;

  @media only screen and (min-width: ${breakpoint.mobileLarge}) {
    width: auto;
  }
`

function Verify() {
  const history = useHistory()
  const [error, setError] = useState('')

  useEffect(() => {
    const verify = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      const userSessionId = urlParams.get('userSessionId')
      const newsletterSubscribed = urlParams.get('agreedNewsletter') === 'true'

      // delete token if exists as it's not needed for Verify & API throws error if expired
      localStorage.removeItem('accessToken')

      try {
        const { refresh_token } = await AuthService.verify(userSessionId, newsletterSubscribed)

        try {
          const response = await AuthService.loginWithToken(refresh_token)

          if (response.status === 'ok') {
            if (response.data.accessToken) {
              // TODO: store refreshToken in Cookie
              // localStorage.setItem('refreshToken', response.data.refreshToken)
              localStorage.setItem('accessToken', response.data.accessToken)
            }

            setTimeout(() => history.push('/inside-area'), 4000)
          } else {
            console.error(response.data)
            setTimeout(() => history.push('/login'), 4000)
          }
        } catch (error) {
          console.error(error)
          setError(error?.data?.data?.error)
          setTimeout(() => history.push('/login'), 4000)
        }
      } catch (error) {
        console.error(error)
        setError(error.message)
      }
    }

    verify()
  }, [history])

  return (
    <div className="Verify container">
      {!error ? (
        <>
          <h1>Verifizierung erfolgreich</h1>

          <div className="currentStepContent">
            <div className="content-left">
              <p>Sie werden automatisch angemeldet...</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <h1>Es ist leider etwas schiefgegangen</h1>

          <div className="currentStepContent">
            <div className="content-left">
              <p>Sie werden zum Login weitergeleitet...</p>
            </div>
          </div>

          <Error>{error}</Error>
        </>
      )}
    </div>
  )
}

export default Verify
