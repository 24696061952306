import { Component } from 'react'
import { defaultSignupResources } from 'resources'

type Props = {
  // props
}

type State = {
  resources?: Object
}

class WebIdSuccess extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      resources: null,
    }
  }

  componentDidMount() {
    this.setState({ resources: defaultSignupResources })

    // Google Tag Manager tracking
    if (PROD) {
      dataLayer.push({
        event: 'funnel',
        funnelType: 'Unbekannt', //Beim regulären Signup: 'SignUp', beim Mitarbeitersignup: 'Mitarbeiter-SignUp', auf der Seite /id-erfolgreich: 'Unbekannt'
        funnelEntry: 'Unbekannt', // Wenn der User vom Minisignup kommt 'Mini-Signup', ansonsten 'Link'
        funnelProduct: 'Unbekannt', // Hier bitte je nach gewähltem Ziel den entsprechenden Wert einsetzen
        funnelStep: 10, // Sparziel: 1, Sparplan: 2, Risiko: 3, Finanzielle Verhältnisse: 4, Finanzielle Erfahrungen: 5, Ihr Plan, 6, Anlagestruktur: 7, Persönliche Angaben: 8, Abschluss: 9, ID erfolgreich: 10
        funnelStepContent: 'ID erfolgreich', //Siehe funnelStep, Bezeichnungen bitte für die jeweiligen Schritte von dort übernehmen
        funnelStepsTotal: 10,
        funnelVersion: 1,
        conversion: 'id-complete', // Bei Schritt 1: 'signup-start', bei Schritt 9 'signup-complete', bei Schritt 10 'id-complete'
      })
    }
  }

  render() {
    const resources = this.state.resources

    if (resources) {
      return (
        <div>
          <h1>{resources.WebIdSuccess.title}</h1>

          <div className="currentStepContent">
            <div className="content-center-lefttext">
              <p>
                {resources.WebIdSuccess.text1}
                {resources.WebIdSuccess.text2 ? <br /> : null}
                {resources.WebIdSuccess.text2}
                <br />
              </p>
              <p>{resources.WebIdSuccess.text3}</p>

              <div className="greetings">
                <p>{resources.WebIdSuccess.greetings.text1}</p>
                <p>{resources.WebIdSuccess.greetings.text2}</p>
                <p>{resources.WebIdSuccess.greetings.text3}</p>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return <div />
    }
  }
}

export default WebIdSuccess
