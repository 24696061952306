// custom fetch wrapper taken from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper
import AuthService from 'services/api/auth'

const API_URL = CUBA_API_URL
const localStorageKey = 'accessToken'

function request<T, U = unknown>(endpoint: string, data?: U, customConfig?: Record<string, unknown>): Promise<T> {
  const token = localStorage.getItem(localStorageKey)

  const config = {
    method: data ? 'POST' : 'GET',
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      ...(data && { 'Content-Type': 'application/json' }),
    },
    ...(data && { body: JSON.stringify(data) }),
    ...customConfig,
  }

  return fetch(`${API_URL}${endpoint}`, config).then(async (response) => {
    // if Status 401 Unauthorized logout and redirect to login
    if (response.status === 401) {
      await AuthService.logout()
      localStorage.removeItem('user')
      localStorage.removeItem(localStorageKey)
      location.assign('/login')

      return Promise.reject({ message: 'Please re-authenticate.' })
    }

    if (response.ok) {
      // if Status 204 No Content return
      if (response.status === 204) {
        return Promise.resolve()
      }

      return await response.json()
    } else {
      return Promise.reject(await response.json())
    }
  })
}

export default request
