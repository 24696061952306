import type { Message } from 'types'
import type { CubaProduct } from 'services/api/product'
import type { Product, User } from 'reducers/user'
import type { SignupData } from 'reducers/signup'
import type { Action } from './types'

export function fetchInitialUser(userData: User | SignupData, userProducts: Product[]): Action {
  return {
    type: 'FETCH_INITIAL_USER',
    payload: {
      userData,
      userProducts,
    },
  }
}

export function fetchUserData(data: User): Action {
  return {
    type: 'FETCH_USER_DATA',
    payload: data,
  }
}

export function fetchUserProducts(userProducts: Product[] | CubaProduct[]): Action {
  return {
    type: 'FETCH_USER_PRODUCTS',
    payload: userProducts,
  }
}

export function updateUserProduct(id: number, productData: Object): Action {
  return {
    type: 'UPDATE_USER_PRODUCT',
    payload: {
      id,
      productData,
    },
  }
}

export function fetchUserMessages(messages: Message[]): Action {
  return {
    type: 'FETCH_USER_MESSAGES',
    payload: messages,
  }
}

export function setMessageRead(msgID: string): Action {
  return {
    type: 'SET_MESSAGE_READ',
    payload: msgID,
  }
}

export function logoutUser(): Action {
  return {
    type: 'LOGOUT',
  }
}
