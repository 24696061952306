import { formatValue, separateDecimalNumber } from '@finabro-ui/components'
import { ProductCategory } from 'reducers/main'

type Props = {
  currencySign?: string
  currentValue?: number
  targetCategory?: number | Object
  productCategories?: ProductCategory[]
  showDetails?: boolean
  reverseOrder?: boolean
  deposit?: number
  targetAmount?: number
  targetRate?: number
  targetYear?: number
  savingsRate?: number
  depositVisible?: boolean
  targetAmountVisible?: boolean
  profileMode?: boolean
  fullname?: string
  isMobile?: boolean
  resources?: Object
}

function ProductInfo({
  currencySign,
  currentValue,
  targetCategory,
  productCategories,
  showDetails,
  reverseOrder,
  deposit,
  targetAmount,
  targetRate,
  targetYear,
  savingsRate,
  depositVisible,
  targetAmountVisible,
  profileMode,
  fullname,
  isMobile,
  resources,
}: Props) {
  // TODO: waiting for thomas to save categories by id
  const categoryID = targetCategory && targetCategory.id ? targetCategory.id : targetCategory

  // NOTE: separate currentValue before and after decimal point for respective styling
  const { integer, decimalPlace } = separateDecimalNumber(currentValue ? currentValue : 0)

  return (
    <div className="ProductInfo">
      {profileMode ? (
        <div className="info">
          <h5>Profil</h5>
          <h2>{fullname}</h2>
        </div>
      ) : (
        targetCategory && (
          <div className="info">
            <h5>Mein Ziel</h5>
            <h2 className={isMobile ? 'CategoryTitle' : undefined}>
              {targetCategory.name
                ? targetCategory.name == 'Anlage' && resources.sidebar.anlage
                  ? resources.sidebar.anlage
                  : targetCategory.name == 'Pension' && resources.sidebar.pension
                  ? resources.sidebar.pension
                  : targetCategory.name == 'Sparen' && resources.sidebar.sparen
                  ? resources.sidebar.sparen
                  : targetCategory.name
                : productCategories.length > 0
                ? productCategories.find((category) => category.id === categoryID).name == 'Anlage' &&
                  resources.sidebar.anlage
                  ? resources.sidebar.anlage
                  : productCategories.find((category) => category.id === categoryID).name == 'Pension' &&
                    resources.sidebar.pension
                  ? resources.sidebar.pension
                  : productCategories.find((category) => category.id === categoryID).name == 'Sparen' &&
                    resources.sidebar.sparen
                  ? resources.sidebar.sparen
                  : productCategories.find((category) => category.id === categoryID).name
                : '–'}
            </h2>
          </div>
        )
      )}

      {!profileMode &&
        (showDetails && reverseOrder ? (
          savingsRate != undefined || deposit != undefined || targetYear != undefined ? (
            <div>
              {savingsRate && (
                <div className="info savingsRate has-subtext">
                  <div>
                    <h5>Sparrate</h5>
                    <span>
                      <h2>
                        {formatValue(savingsRate)}
                        {currencySign}
                      </h2>
                      <p className="xs">(pro Monat)</p>
                    </span>
                  </div>
                </div>
              )}

              {deposit && (
                <div className="info deposit">
                  {depositVisible && (
                    <>
                      <h5>Einmalbetrag</h5>
                      <h2>
                        {formatValue(deposit)}
                        {currencySign}
                      </h2>
                    </>
                  )}
                </div>
              )}

              {targetYear && (
                <div>
                  {targetAmountVisible && (
                    <div className={`info targetAmount ${targetRate && 'has-subtext'}`}>
                      <h5>Zielbetrag</h5>
                      <h2>{targetAmount ? `${formatValue(targetAmount)}${currencySign}` : '-'}</h2>
                    </div>
                  )}

                  <div className="info targetYear">
                    <h5>Im Jahr</h5>
                    <h2>{targetYear ? targetYear : '-'}</h2>
                  </div>
                </div>
              )}
            </div>
          ) : null
        ) : showDetails ? (
          targetYear != undefined || deposit != undefined || savingsRate == undefined ? (
            <div>
              {targetYear && (
                <div>
                  {targetAmountVisible && (
                    <div className={`info targetAmount ${targetRate && 'has-subtext'}`}>
                      <h5>Zielbetrag</h5>
                      <h2>{targetAmount ? `${formatValue(targetAmount)}${currencySign}` : '-'}</h2>
                    </div>
                  )}

                  <div className="info targetYear">
                    <h5>Im Jahr</h5>
                    <h2>{targetYear ? targetYear : '-'}</h2>
                  </div>
                </div>
              )}

              {deposit && (
                <div className="info deposit">
                  {depositVisible && (
                    <>
                      <h5>{resources.sidebar.deposit}</h5>
                      <h2>
                        {formatValue(deposit)}
                        {currencySign}
                      </h2>
                    </>
                  )}
                </div>
              )}

              {savingsRate && (
                <div className="info savingsRate has-subtext">
                  <h5>{resources.sidebar.savingsRate}</h5>
                  <span>
                    <h2>
                      {formatValue(savingsRate)}
                      {currencySign}
                    </h2>
                    <p className="xs">{resources.sidebar.xsSavingsRate}</p>
                  </span>
                </div>
              )}
            </div>
          ) : null
        ) : (
          <div>
            <div className="info">
              <h5>Aktueller Wert</h5>
              <h2>
                {integer},<span className="superscript">{decimalPlace}</span>
                {currencySign}
              </h2>
            </div>
          </div>
        ))}
    </div>
  )
}

export default ProductInfo
