import { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

// Code-splitting
const Admin = lazy(() => import(/* webpackChunkName: "crm" */ 'components/Admin/Admin'))
const Signup = lazy(() => import(/* webpackChunkName: "signup" */ 'components/Signup/SignupRouter'))
const InsideArea = lazy(
  () => import(/* webpackChunkName: "inside", webpackPrefetch: true */ 'components/InsideArea/InsideArea'),
)

import { Auth } from 'components/Authentication'
import { WebIdTemplate, WebIdFail, WebIdSuccess, WebSignatureSuccess } from 'components/Signup/WebId'
import SignupDocumentsSent from 'containers/Signup/SignupDocumentsSent'
import LoadingScreen from 'components/UI/LoadingScreen'
import PrivateRoute from 'components/PrivateRoute'
import NoMatch from 'components/NoMatch'

const AppRouter = () => (
  <HelmetProvider>
    <Router>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          {/* Inside Area */}
          <PrivateRoute path="/inside-area">
            <InsideArea />
          </PrivateRoute>

          {/* CRM */}
          <PrivateRoute path="/service-area">
            <Admin />
          </PrivateRoute>

          {/* Signup */}
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/firmen-signup">
            <Signup />
          </Route>
          <Route path="/complete-signup">
            <Signup />
          </Route>
          <Route path="/signup-resume">
            <Signup />
          </Route>

          {/* WebID */}
          {/* TODO: move WebID Routes into Signup */}
          <Route path="/contract-info-send-again">
            <SignupDocumentsSent />
          </Route>

          <Route path="/id-erfolgreich">
            <WebIdTemplate>
              <WebIdSuccess />
            </WebIdTemplate>
          </Route>

          <Route path="/id-nicht-erfolgreich">
            <WebIdTemplate>
              <WebIdFail />
            </WebIdTemplate>
          </Route>

          <Route path="/signatur-erfolgreich">
            <WebIdTemplate>
              <WebSignatureSuccess />
            </WebIdTemplate>
          </Route>

          {/* 404 */}
          <Route path="/seite-nicht-gefunden" exact>
            <NoMatch />
          </Route>

          {/* Authentication */}
          <Route path="/">
            <Auth />
          </Route>

          {/* 404 */}
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  </HelmetProvider>
)

export default AppRouter
