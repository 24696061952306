import { useState, useEffect, useRef, ChangeEvent } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { TextField, LegacyButton as Button, color, breakpoint } from '@finabro-ui/components'

import AuthService from 'services/api/auth'
import { isValidEmail } from 'utils/helpers'
import { defaultInsideResources as resources } from 'resources'

import { Wrapper, Column, Title, Subtitle } from 'components/Authentication/Auth'
import LoadingSpinner from 'components/UI/LoadingSpinner'

const StyledButton = styled(Button)`
  margin: 20px 0;
  width: 100%;

  @media only screen and (min-width: ${breakpoint.mobileLarge}) {
    max-width: 250px;
  }
`

const StyledLink = styled(Link)`
  && {
    color: ${color.mediumdark};
    font-size: 0.75rem;
  }
`

const PasswordReset = () => {
  const location = useLocation()
  const history = useHistory()
  const [email, setEmail] = useState(location?.state?.email ?? '')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const emailRef = useRef<HTMLInputElement>()

  useEffect(() => {
    emailRef?.current?.focus()
  }, [])

  async function onSubmit(): Promise<void> {
    setIsLoading(true)
    setError(null)

    try {
      const response = await AuthService.resetPassword(email)

      if (response.status === 'ok') {
        history.push('/passwort-vergessen/erfolgreich')
      } else {
        // TODO: error handling
        console.error('response error: ', response.data.error)
      }

      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Wrapper>
      <Column>
        <Title>Passwort ändern?</Title>
        <Subtitle>Bitte geben Sie Ihre E-Mail Adresse ein, um Ihr Passwort zurückzusetzen.</Subtitle>

        {!isLoading ? (
          <>
            <form onSubmit={onSubmit}>
              <TextField
                id="email"
                label={resources.login.email.label}
                type="email"
                value={email}
                autoComplete="email"
                margin="normal"
                tabIndex={1}
                inputRef={emailRef}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
              />

              {error && <p className="error">{error}</p>}

              <StyledButton id="button-submit" isDisabled={!isValidEmail(email)}>
                Passwort ändern
              </StyledButton>
            </form>

            <StyledLink id="link-login" to="/login">
              Zurück zum Login
            </StyledLink>
          </>
        ) : (
          <div className="container">
            <LoadingSpinner text="Passwort wird zurückgesetzt..." />
          </div>
        )}
      </Column>
    </Wrapper>
  )
}

export default PasswordReset
