import { createTheme } from '@material-ui/core/styles'
import { color, typography } from '@finabro-ui/components'

const muiTheme = createTheme({
  typography: {
    fontFamily: typography.type.primary,
  },
  palette: {
    primary: {
      light: color.primary,
      main: color.primary,
      dark: color.primary,
    },
    secondary: {
      light: color.secondary,
      main: color.secondary,
      dark: color.secondary,
    },
    text: {
      primary: color.secondary,
      secondary: color.secondary,
    },
  },
  overrides: {
    // the way this works: look into the styles within MUI Input.js, and write a corresponding style here
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid rgb(224, 224, 224)',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: '1px solid rgb(224, 224, 224)',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(0, 0, 0, 0.3)', // label color in unselected text input
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: color.primary,
      },
    },
    MuiNativeSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
})

export default muiTheme
