import { TransitionGroup } from 'react-transition-group'
import CSSTransition from 'react-transition-group/CSSTransition'

import HelpBoxContentService from '../../../services/HelpBoxContentService'
import AbstractSidebarBox from './AbstractSidebarBox'

type Props = {
  // props
}

type State = {
  content: Object
}

class HelpBox extends AbstractSidebarBox {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)
    this.setContent = this.setContent.bind(this)
    this.state = { content: HelpBoxContentService.getInstance().getContent() }
    HelpBoxContentService.getInstance().registerListener(this.setContent)
  }

  setContent(content, ignoreUpdate) {
    this.setState({ content })

    // this.state.content = content;
    // if (!ignoreUpdate) {
    //   this.forceUpdate();
    // }
  }

  renderContent() {
    const { content } = this.state

    return (
      <div className="helpbox">
        <div className="helpbox__title">
          <h2>{content.title}</h2>
        </div>

        <TransitionGroup appear>
          {content.items.map((item, index) => {
            if (item.type === 'item') {
              return (
                <CSSTransition key={'transition' + index} in classNames="item" timeout={1000 * (index + 1)}>
                  <div className="helpbox__item">
                    <div key={'item' + index} className="item" dangerouslySetInnerHTML={{ __html: item.text }} />
                  </div>
                </CSSTransition>
              )
            } else if (item.type === 'button') {
              return (
                <CSSTransition key={'transition' + index} in classNames="item" timeout={1000 * (index + 1)}>
                  <div className="helpbox__item">
                    <div key={'item' + index} className="item buttonContainer">
                      <button className="button" onClick={item.action}>
                        {item.text}
                      </button>
                    </div>
                  </div>
                </CSSTransition>
              )
            }
          })}
        </TransitionGroup>
      </div>
    )
  }

  isVisible() {
    const { content } = this.state
    return content.title
  }

  getIconClassName() {
    const { content } = this.state
    return content.iconClassName
  }
}

export default HelpBox
