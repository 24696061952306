import { useState, useEffect, useRef, ChangeEvent, useCallback } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import InputAdornment from '@material-ui/core/InputAdornment'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { TextField, PasswordStrengthMeter, LegacyButton as Button, color, breakpoint } from '@finabro-ui/components'

import AuthService from 'services/api/auth'
import { Wrapper, Column, Title, Form } from 'components/Authentication/Auth'
import LoadingSpinner from 'components/UI/LoadingSpinner'

const UUID_LENGTH = 36

const StyledColumn = styled(Column)`
  @media only screen and (min-width: ${breakpoint.mobileLarge}) {
    max-width: 470px;
  }
`

const StyledButton = styled(Button)`
  margin: 20px 0;
  width: 100%;

  @media only screen and (min-width: ${breakpoint.mobileLarge}) {
    max-width: 250px;
  }
`

const StyledLink = styled(Link)`
  && {
    color: ${color.mediumdark};
    font-size: 0.75rem;
  }
`

const StyledIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    fill: ${color.dark};
  }
`

// accessible via URL: /passwort-vergessen/YjIyMmE5NmUtOTE5My00ODFhLWFmN2MtNmFhZDQxZTQ5MGIzcGhpbGlwcC53YWx0ZXJAZmluYWJyby5jb20=
const PasswordUpdate = () => {
  const params = useParams()
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)
  const [passwordsNotEqual, setPasswordsNotEqual] = useState(false)
  const [passwordScore, setPasswordScore] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState('')

  const autoFocusRef = useRef<HTMLInputElement>()

  const decodedData = typeof params.secret !== 'undefined' ? atob(params.secret) : null
  const email = decodedData?.slice(UUID_LENGTH)
  const secret = decodedData?.slice(0, UUID_LENGTH)

  useEffect(() => {
    autoFocusRef?.current?.focus()
  }, [])

  // CHECK SECRET
  const checkPasswordReset = useCallback(async () => {
    try {
      const response = await AuthService.checkPasswordReset(email, secret)

      if (response.status !== 'ok') {
        history.push('/passwort-vergessen')
      }
    } catch (error) {
      console.error(error)
      history.push('/passwort-vergessen')
    }
  }, [email, secret, history])

  useEffect(() => {
    if (email && secret) {
      checkPasswordReset()
    } else {
      history.push('/passwort-vergessen')
    }
  }, [email, secret, checkPasswordReset, history])

  // PASSWORD
  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)

    if (passwordRepeat) {
      setPasswordsNotEqual(!(passwordRepeat === event.target.value))
    }
  }

  const onChangePasswordRepeat = (event: ChangeEvent<HTMLInputElement>) => {
    setPasswordRepeat(event.target.value)
    setPasswordsNotEqual(!(password === event.target.value))
  }

  // SUBMIT
  const onSubmit = async (): Promise<void> => {
    setIsLoading(true)
    setError(null)

    try {
      const response = await AuthService.updatePassword(email, secret, password)

      if (response.status === 'ok') {
        setIsSuccess(true)
      } else {
        const error =
          response?.data?.error?.length > 0
            ? response.data.error
            : 'Ein Fehler ist aufgetreten. Bitte kontaktieren Sie uns.'

        setError(error)
      }
    } catch (error) {
      console.error(error)
      setError(error)
    }

    setIsLoading(false)
  }

  const navigateToLogin = () => {
    if (PROD) {
      location.assign('https://finabro.app.link')
    } else {
      location.assign('https://finabro.test-app.link')
    }
  }

  return (
    <Wrapper>
      <StyledColumn>
        {isSuccess && (
          <>
            <Title>Ihr Passwort wurde erfolgreich geändert</Title>

            <StyledButton id="button-submit" onClick={navigateToLogin}>
              Zum Login
            </StyledButton>
          </>
        )}

        {!isSuccess && (
          <>
            <Title>Neues Passwort eingeben</Title>

            {!isLoading ? (
              <>
                <Form onSubmit={onSubmit}>
                  <TextField
                    id="password"
                    label="Passwort"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    autoComplete="new-password"
                    margin="normal"
                    tabIndex={0}
                    inputRef={autoFocusRef}
                    endAdornment={
                      <InputAdornment position="end">
                        <StyledIcon onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </StyledIcon>
                      </InputAdornment>
                    }
                    onChange={onChangePassword}
                  />

                  <TextField
                    id="password-repeat"
                    label="Passwort bestätigen"
                    type={showPasswordRepeat ? 'text' : 'password'}
                    value={passwordRepeat}
                    autoComplete="new-password"
                    margin="normal"
                    tabIndex={1}
                    error={passwordsNotEqual}
                    helperText={passwordsNotEqual && 'Passwort stimmt nicht überein'}
                    endAdornment={
                      <InputAdornment position="end">
                        <StyledIcon onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}>
                          {showPasswordRepeat ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </StyledIcon>
                      </InputAdornment>
                    }
                    onChange={onChangePasswordRepeat}
                    onSubmit={onSubmit}
                  />

                  <PasswordStrengthMeter password={password} onChangeScore={setPasswordScore} />

                  {error && <p className="error">{error}</p>}

                  <StyledButton id="button-submit" isDisabled={!(password === passwordRepeat) || passwordScore < 2}>
                    Passwort neu vergeben
                  </StyledButton>
                </Form>

                <StyledLink id="link-login" to="/login">
                  Zurück zum Login
                </StyledLink>
              </>
            ) : (
              <div className="container">
                <LoadingSpinner text="Passwort wird aktualisiert..." />
              </div>
            )}
          </>
        )}
      </StyledColumn>
    </Wrapper>
  )
}

export default PasswordUpdate
